import React from 'react'
import './LigjiArsimitParauniversitar.scss'
import { Link } from 'react-router-dom'

function LigjiArsimitParauniversitar() {
  return (
    <div className='container ligjiarsimitparauniversitar-container'>
      <div className="neni-container col-lg-10 col-md-12 col-sm-12">
        <div className="pikat-nenit-container">
            <div className="pikat-nenit-title-container">
              <h4 className='pikat-nenit-title'>Neni 40</h4>
              <p className='pikat-nenit-desc'>Parimi i arsimit gjithëpërfshirës</p>
            </div>
            <p>1. Qëllimi i qartë i këtij Ligji është që parimi i arsimit gjithëpërfshirës të zbatohet në Republikën e Kosovës si praktika më e mirë në përputhje me normat ndërkombëtare, 
              si parashihen në Konventën e UNESCO-s për të Drejtat e Fëmijëve (1989), në Deklaratën e Salamankës (1994),
              Konventën e OKB-së për të Drejta të Personave me Aftësi të Kufizuara (2007) dhe në konventat apo rekomandimet e tjera përkatëse ndërkombëtare.
            </p>
            <p><span>2.</span> Parimi i arsimit gjithëpërfshirës nënkupton se:</p>
            <p><span>2.1.</span> institucionet arsimore dhe aftësuese duhet të akomodojnë të gjithë fëmijët, pa dallim të kushteve të tyre fizike, intelektuale, sociale, gjuhësore apo të tjera dhe duhet të promovojnë integrimin dhe kontaktin mes fëmijëve;</p>
            <p><span>2.2.</span> mbështetja përkatëse duhet të ofrohet bazuar në nevojat intelektuale të nxënësve; dhe</p>
            <p><span>2.3.</span> ambientet e posaçme arsimore apo shkollat speciale arsyetohen vetëm pasi që nga vlerësimi i ekspertit del se nuk është praktike të regjistrohet ndonjë fëmijë në shkollën e rregullt publike apo në institucionin e aftësimit.</p>
            <p><span>3.</span> Komunat marrin masa për të mbështetur përfshirjen e fëmijëve në shkolla të rregullta të komunës, përfshirë edhe themelimin e dhomave burimore dhe klasave të përshtatura për nxënësit me aftësi të kufizuara fizike, përfshirë ato ndijore.</p>
            <p><span>4.</span> Sipas vlerësimeve individuale profesionale, komunat sigurojnë transport falas, të sigurt dhe të përshtatshëm për fëmijët me aftësi të kufizuara apo me vështirësi në mësim që ndjekin mësimet në shkollë komunale.</p>
          </div>
          <p>Dokumentin e plotë mund ta gjeni <Link to={`/modules/moduli-1/burimet`}>këtu.</Link></p>
      </div>
      <div className="content-card-container col-lg-10 col-md-12 col-sm-12">
        <p>Qëllimi i qartë i këtij Ligji është që parimi i arsimit gjithëpërfshirës të zbatohet në Republikën e Kosovës si praktika më e mirë në përputhje me normat ndërkombëtare, 
          si parashihen në Konventën e OKB-së për të Drejtat e Fëmijës (1989), në Deklaratën e Salamankës (1994), Konventën e OKB-së për të Drejta të Personave me Aftësi të Kufizuara 
          (2007) dhe në konventat apo rekomandimet e tjera përkatëse ndërkombëtare.
        </p>
      </div>
    </div>
  )
}

export default LigjiArsimitParauniversitar