import React from 'react'
import './HyrjeInstrumtet.scss'

function HyrjeInstrumtet() {
  return (
    <div className='container hyrjeinstrumentet-container'>
        <div className="col-lg-10 col-md-12 col-sm-12">
            <h3>Funksionimi dhe aftësia e kufizuar</h3>
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 hyrjeinstrumentet-subcontainer">
            <div className="paragraph-container">
                <p>
                Ministria e Arsimit, Shkencës, Teknologjisë dhe Inovacioni bazuar në Ligjin Nr. 04/L-032 për Arsimin Parauniversitar në Republikën e Kosovës, neni 41; si
                dhe Udhëzimin Administrativ 16/2017 për Vlerësimin pedagogjik të fëmijëve me nevoja të veçanta arsimore në Kosovë, ka hartuar instrumentet për vlerësimin pedagogjik të funksionimit të fëmijëve 
                me nevoja të veçanta dhe vështirësi në pjesëmarrje. Instrumentet janë hartuar nga Divizioni për arsim gjithëpërfshirës në bashkëpunim me kordinatorët e Ekipeve vlerësuese 
                në 7 komuna, të udhëhequr nga profesorë nga Universiteti i Bolonjës dhe Universiteti i Padovës në Itali.
                </p>
                <br />
                <p>
                Instrumentet e zhvilluara kanë për bazë modelin biopsikosocial të Klasifikimit Ndërkombëtar të Funksionimit, Aftësisë së Kufizuar dhe Shëndetit - versioni për Fëmijë dhe të Rinj (KNF-FR).
                Instrumentet janë të dizajnuara që t’i përgjigjen të gjitha llojeve të aftësisë së kufizuar sipas qasjes biopsikosociale, e cila udhëzon vlerësimin e fëmijëve në të gjitha aspektet.
                </p>
            </div>
            <div className="image-container"></div>
        </div>
    </div>
  )
}

export default HyrjeInstrumtet