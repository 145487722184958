import React from "react";
import { Navigate, Route } from "react-router-dom";

const ProtectedRoute = ({ path, exact, children, from }) => {
  return localStorage.getItem('token') ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: from }} />
  );
};

export default ProtectedRoute;
