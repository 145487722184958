import React, { useEffect, useState } from 'react';
import './NdaloReflekto.scss';
import { toast } from "react-toastify";
import { api } from '../../../api/axios';

function NdaloReflekto({userId, description, placeholder, moduleId, questionSlug}) {
    const [reflectionAnswer, setReflectionAnswer] = useState([]);
    const [originalAnswer, setOriginalAnswer] = useState([]);
    const [quizType, setQuizType] = useState('Ndalo Reflekto')
    
    function handleInput(e) {
        setReflectionAnswer([e.target.value]);
    }    

    async function sendNdaloReflektoData() {
        const payload = {
            user: {
                _id: userId
            },
            moduleId: moduleId,
            questionSlug: questionSlug,
            questionText: [description],
            quizType: quizType,
            answerOptions: reflectionAnswer,
        }
        
        try {
            await api.post(`miniquiz/submitMiniQuiz/${moduleId}`, payload);
            toast.success("Pergjigja juaj eshte ruajtur me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        } catch (error) {
            console.log('There\'s an error!!', error.message);
        }
    }

    async function fetchAnswerValue() {
        try {
          let fetchAnswer = await api.get(`miniquiz/${userId}/${moduleId}/${questionSlug}/${quizType}`);
          const answerOption = fetchAnswer?.data?.data?.answerOptions;
          if (answerOption && answerOption.length > 0) {
            setOriginalAnswer(answerOption[0]);
            setReflectionAnswer(answerOption[0]);
          }
        } catch (error) {
          console.log(error);
        }
      }

    async function updateAnswerValue() {
        try {
            const response = await api.put(`miniquiz/${userId}/${moduleId}/${questionSlug}`, {
                answerOptions: reflectionAnswer,
                quizType: quizType
            });
            toast.success("Pergjigja juaj eshte perditesuar me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        if (userId) {
            fetchAnswerValue();
        }
    }, [userId, moduleId, questionSlug]);

    const showEditoButton = reflectionAnswer !== originalAnswer;

    return (
        <div className='ndalo-reflekto-container'>
            <div className="ndalo-reflekto-title-container">
                <h4>Ndalo dhe reflekto.</h4>
                <p>{description}</p>
            </div>

            <div className="input-container">
                <input 
                    type="text"
                    name="ndaloreflekto" 
                    id="ndaloreflekto" 
                    onChange={handleInput} 
                    value={reflectionAnswer}
                    placeholder={!reflectionAnswer ?  '' : placeholder }/>
            </div>

            <div className="buttons-container">
                <button className="btn-container" onClick={sendNdaloReflektoData}>
                    <h5 className="btn-text">Ruaje</h5>
                </button>
                {showEditoButton && (
                    <button className="btn-container" onClick={updateAnswerValue}>
                        <h5 className="btn-text">Ndrysho</h5>
                    </button>
                )}
            </div>
        </div>
    );
}

export default NdaloReflekto;
