import React from 'react'
import './Video.scss'

function Video({urlSource}) {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 video-container">
        <iframe
            width="100%"
            height="442"
            className='video'
            src={urlSource}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    </div>
  )
}

export default Video