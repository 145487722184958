import React from 'react'
import './DemtimetTekIndividet.scss'

function DemtimetTekIndividet() {
  return (
    <div className="main-container container">
    <div className="list col-lg-10 col-md-12 col-sm-12">
      <div className="list-item">
        <div className="list-bullet"></div>
        <p className="list-item-text"><span className='list-item-text-span'>Dëmtimet fizike</span> ndikojnë në lëvizjen dhe / ose pamjen e trupit të një personi.</p>
      </div>
      <div className="list-item">
        <div className="list-bullet"></div>
        <p className="list-item-text"><span className='list-item-text-span'>Dëmtimet neurologjike</span> ndikojnë në sistemin nervor, të folurit, aftësitë motorike, vizionin, kujtesën, muskujt, aftësitë e të mësuarit (p.sh. epilepsia, skleroza e shumëfishtë).</p>
      </div>
      <div className="list-item">
        <div className="list-bullet"></div>
        <p className="list-item-text"><span className='list-item-text-span'>Dëmtimet intelektuale</span> ndikojnë aftësitë mendore.
        </p>
      </div>
      <div className="list-item">
        <div className="list-bullet"></div>
        <p className="list-item-text"><span className='list-item-text-span'>Dëmtimet shqisore</span> ndikojnë shikimin, dëgjimin, të folurit, nuhatjen, shijimin, ndjesitë, bilancin fizik.</p>
      </div>
    </div>
  </div>
  )
}

export default DemtimetTekIndividet