import { put, takeLatest } from "redux-saga/effects";
import { setToken, removeToken } from "../../../api/utils";
import { showLoader } from "../loader/actions";
import { setLoginMessage, setLoginUser, setLogout, updateUser } from "./actions";
import {
  LOGIN_USER_DISPATCHER,
  LOGOUT_USER,
  REGISTER_USER_DISPATCHER,
  UPDATE_USER,
  VALIDATE_TOKEN,
} from "./type";
import { api } from "../../../api/axios";

// watcher
export function* userWatcher() {
  yield takeLatest(LOGIN_USER_DISPATCHER, LOGINUSERDISPATCHER);
  yield takeLatest(VALIDATE_TOKEN, VALIDATETOKEN);
  yield takeLatest(LOGOUT_USER, LOGOUT);
  yield takeLatest(UPDATE_USER, UPDATELOCALSTORAGE);
  yield takeLatest(REGISTER_USER_DISPATCHER, REGISTERUSERDISPATCHER);
}

export function* LOGINUSERDISPATCHER(data) {
  try {
    const response = yield api.post("/auth/login", data.values);

    yield put(showLoader(true));
    yield put(
      setLoginMessage({ type: "success", msg: "Ju u kyqet me sukses!" })
    );
    const { user, token, exp } = response.data.data; // Access the nested data object
    yield put(setLoginUser(user, token, exp));
    yield setToken(user, token, exp); // Save to local storage
  } catch (error) {
    yield put(
      setLoginMessage({ type: "error", msg: error.response.data.message })
    );
    yield put(showLoader(false));
  } finally {
    yield put(showLoader(false));
  }
}

export function* REGISTERUSERDISPATCHER(data) {
  try {
    yield api.post("/auth/register", data.values);
    yield put(showLoader(true));
    yield put(
      setLoginMessage({ type: "success", msg: "Linku per verifikimin e llogarise suaj eshte derguar ne email adrese. Faleminderit!" })
    );
  } catch (error) {
    yield put(
      setLoginMessage({ type: "error", msg: error.response.data.message })
    );
    yield put(showLoader(false));
  } finally {
    yield put(showLoader(false));
  }
}

export function* LOGOUT({ email, password }) {}

export function* UPDATELOCALSTORAGE(data) {
  yield setToken(data.user, data.token, data.exp);
}

export function* VALIDATETOKEN({ token, user, exp }) {
  try {
    const validation = yield api.post("/auth/validate");
    if (validation.data.success) {
      const { token, user, exp } = validation.data.data;
      yield put(updateUser(token, user, exp)); // Dispatch action to update Redux state
    } else {
      yield removeToken();
      yield put(setLogout()); // Ensure logout action is dispatched to update state
    }
  } catch (e) {
    yield put(setLoginMessage("Please try again later"));
    yield removeToken();
    yield put(setLogout()); // Logout action for failed validation
  } finally {
    yield put(showLoader(false));
  }
}

