export const vleresimiPedagogjikData = [
  {
    id: 1,
    cardTitle: 'Përbërja e ekipit vlerësues në nivel komunal:',
    backgroundColor: '#FFF3E8',
    list: [
      {
        id: 1,
        listItem: 'Pedagogu/pedagogu special,',
      },
      {
        id: 2,
        listItem: 'Psikologu,',
      },
      {
        id: 3,
        listItem: 'Logopedi,',
      },
      {
        id: 4,
        listItem: 'Punëtori social.',
      },
    ],
  },
  {
    id: 2,
    cardTitle: 'Inicimi i procedurës së vlerësimit:',
    backgroundColor: '#E8F3FF',
    list: [
      {
        id: 1,
        listItem: 'Prindi apo kujdestari ligjor i fëmijës,',
      },
      {
        id: 2,
        listItem:
          'Drejtori i institucionit arsimor ku është i regjistruar fëmija,',
      },
      {
        id: 3,
        listItem: 'Me kërkesën e mësimdhënësit apo kujdestarit të klasës,',
      },
      {
        id: 4,
        listItem: 'Zyrtari i arsimit gjithëpërfshirës në DKA,',
      },
      {
        id: 5,
        listItem: 'Qendra e mjeksisë familjare.',
      },
    ],
  },
  {
    id: 3,
    cardTitle: 'Procedura e vlerësimit:',
    backgroundColor: '#EFE8FF',
    list: [
      {
        id: 1,
        listItem: '15 ditë nga pranimi i kërkesës në DKA,',
      },
      {
        id: 2,
        listItem: 'Prindi merr pjesë në takimin e ekipit,',
      },
      {
        id: 3,
        listItem:
          'Shkolla pranon raportin me rekomandime brenda 5 ditësh pas vlerësimit,',
      },
      {
        id: 4,
        listItem: 'Ekipi mbanë procesverbal të rregullt për çdo takim,',
      },
      {
        id: 5,
        listItem:
          'Drejtori i institucionit arsimor është përgjegjës për zbatim,',
      },
      {
        id: 6,
        listItem:
          'Ekipi shqyrton të gjitha vendimet e veta së paku një herë në dy vite.',
      },
    ],
  },
  {
    id: 4,
    cardTitle: 'Ekipi vlerësues përcakton:',
    backgroundColor: '#FFE8F0',
    list: [
      {
        id: 1,
        listItem:
          'Institucionin arsimor në të cilin fëmija do të vijoj mësimin,',
      },
      {
        id: 2,
        listItem: 'Llojin e mësimdhënies,',
      },
      {
        id: 3,
        listItem: 'Shërbimet e mbështetjes,',
      },
      {
        id: 4,
        listItem: 'Pajisjet dhe instrumentet e nevojshme ndihmëse,',
      },
      {
        id: 5,
        listItem: 'Shërbimet rehabilituese,',
      },
      {
        id: 6,
        listItem: 'Transportin e përshtatshëm.',
      },
    ],
  },
];
