import React, { useState, useEffect } from 'react';
import './FormulariRaportimit.scss';
import { questions } from './questions';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { api } from '../../../../../api/axios';
import { toast } from 'react-toastify';
import Video from '../../../../shared/Video/Video';

function FormulariRaportimit() {

    const { moduleId, moduleClass } = useParams()
    const [userId, setUserId] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [sendTableValuesBtn, setSendTableValuesBtn] = useState(true);

    const handleToggle = (questionId, value) => {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [questionId]: value,
      }));
    };

    async function fetchUserData() {
        try {
            const data = await api.get(`user/myDetails`);
            setUserId(data.data.data._id); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function handleTableSubmittion() {
        // Validate answers
        const wrongAnswers = questions.filter(question => {
            const selectedValue = selectedValues[`question${question.number}`];
            const isAnswerCorrect = question.options.find(option => option.value === selectedValue)?.label === question.correctAnswer;
            return !isAnswerCorrect;
        }).map(question => `Pyetja ${question.number}`);

        if (wrongAnswers.length > 0) {
            toast.error(`Gabim tek: ${wrongAnswers.join(', ')}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return; 
        }

        try {
            const questionText = [];
            const answerOptions = [];
    
            questions.forEach((question) => {
                const selectedValue = selectedValues[`question${question.number}`];
                const selectedOption = question.options.find(option => option.value === selectedValue);
                if (selectedOption) {
                    questionText.push(question.content1 + question.highlightContent + question.content2);
                    answerOptions.push(selectedOption.label);
                }
            }); 

            const response = await api.post(`miniquiz/submitMiniQuiz/${moduleId}`, {
                user: { _id: userId },
                moduleId: moduleId,
                questionSlug: moduleClass,
                quizType: 'Table',
                questionText,
                answerOptions
            });

            toast.success("Pergjigja juaj eshte ruajtur me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setSendTableValuesBtn(true);
        } catch (error) {
            console.error('Error on sending data', error);
        }
    }

    async function fetchTableValues() {
        if (!userId) {
            console.error('UserId is null or undefined');
            setSendTableValuesBtn(false);
            return;
        }
        try {
            let fetchAnswer = await api.get(`miniquiz/${userId}/${moduleId}/${moduleClass}/Table`);
            const fetchedAnswers = fetchAnswer?.data?.data?.answerOptions;
            const newSelectedValues = {};
            questions.forEach((question, index) => {
                newSelectedValues[`question${question.number}`] = fetchedAnswers[index] === 'True' ? question.options[0].value : question.options[1].value;
            });
            setSelectedValues(newSelectedValues);
            setSendTableValuesBtn(fetchedAnswers && fetchedAnswers.length > 0)
        } catch (error) {
            console.error('Error:', error);
            setSendTableValuesBtn(false)
        }
    }

    async function handleTableUpdate() {
        try {
            const questionText = [];
            const answerOptions = [];
            questions.forEach((question) => {
                const selectedValue = selectedValues[`question${question.number}`];
                const selectedOption = question.options.find(option => option.value === selectedValue);
                if (selectedOption) {
                    questionText.push(question.content1 + question.highlightContent + question.content2);
                    answerOptions.push(selectedOption.label);
                }
            });
    
            const response = await api.put(`miniquiz/${userId}/${moduleId}/${moduleClass}/multiple`, {
                user: { _id: userId },
                moduleId: moduleId,
                quizType: 'Table',
                questionSlug: moduleClass,
                questionText,
                answerOptions
            });
    
            toast.success("Pergjigjjet tuaja janë përditësuar me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
    
        } catch (error) {
            console.error('Error on updating data', error);
        }
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    useEffect(() => {
        if (userId) {
            fetchTableValues();
        }
    }, [userId, moduleId, moduleClass]);

    return (
        <div className="formulariraportimit-container container">
            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p><span>Për çfarë shërben?</span></p>
                <br />
                <p>Ky formular i shërben shkollës për të informuar dhe raportuar tek Ekipi Vlerësues në nivel komunal rreth funksionimit të
                    fëmijës/nxënësit në kontekstin shkollor, bazuar në vështirësitë e hasura, veprimet e ndërmarra nga shkolla dhe arsyet e mos 
                    tejkalimit të vështirësive, ashtu siç pritej.
                </p>
                <br />
                <p>Në këtë rast, shkolla e konsideron të nevojshme përfshirjen e Ekipit Vlerësues Komunal për vlerësimin e funksionimit të fëmijës/nxënësit, me qëllim të përcaktimit të profilit të tij/saj në aspektin bio-psikosocial.</p>
                <br />
                <p>Plotësimi i këtij formulari bën të mundur që fëmija/nxënësi të marrë shërbimet e duhura nga komuna dhe nga institucionet e tjera përkatëse.</p>
                <br />
                <p><span>Kush është përgjegjës për plotësimin e formularit?</span></p>
                <br />
                <p>Përgjegjës për plotësimin e këtij formulari është mësimdhënësi(ja)/kujdestari(ja) i/e klasës, i cili mund të bashkëpunojë me shërbimin psiko-pedagogjik të shkollës (psikologun, pedagogun, mësimdhënësin mbështetës).</p>
                <br />
                <p>Ky formular nënshkruhet nga mësimdhënësi(ja)/kujdestari(ja) i/e klasës dhe drejtori(ja) i/e shkollës. Prindi,
                me nënshkrimin e tij/saj, pajtohet me vazhdimin e procesit të vlerësimit të fëmijës nga Ekipi Vlerësues në nivel komunal.</p>
                <br />
                <p><span>Periudha kur fëmija raportohet / referohet për vlerësim</span></p>
                <br />
                <p>Nxënësi(ja) parashihet që të referohet për vlerësim pedagogjik tek Ekipi vlerësues komunal, duke filluar nga fundi i muajit nëntor, pra pasi që mësimdhënësi të ketë vëzhguar sistematikisht fëmijën/nxënësin për rreth tre muaj.</p>
                <br />
                <p>Në rastet kur fëmija/nxënësi ka një diagnozë dhe/ose vlerësim paraprak, shkolla mund të kërkojë vlerësim apo rivlerësim të fëmijës/nxënësit edhe në periudha të tjera – jodosdoshmërisht nga fundi i muajit nëntor apo pas tre
                muajsh vëzhgimi sistematik në kontekstin e klasës.</p>
            </div>
            <Video urlSource="https://www.youtube.com/embed/ISCjdbSNIgQ" />
            <div className="scroll-container">
                <div className="scroll-content">
                <p className='title'>Përtej diagnozës</p>
                <p>Beni është një 8 vjeçar i diagnostikuar me dëmtime intelektuale. Ai mund të komunikoj verbalisht, por me disa vështirësi në artikulim të drejtë të fjalëve. Nga shtatori i këtij viti, për shkak të ndërrimit të vendbanimit, Beni është transferuar nga një shkollë tjetër dhe tani është në klasën e dytë në shkollën “Naim Frashëri”. Beni ka përfunduar klasën e parë në një shkollë tjetër, por para se të regjistrohet në shkollën fillore, ai nuk ka vijuar ndonjë institucion parashkollorë.</p><br></br>
                <p>Beni në fillim të vitit të ri shkollor ka shfaqur disa vështirësi në socializimin me fëmijët tjerë dhe ka shfaq disa vështirësi në të nxënë.  Mësuesja ka vëzhguar sistematikisht sjelljen dhe performancën e tij në klase për gati 3 muaj tashmë. Kjo i ka mundësuar mësueses të identifikojë informata të rëndësishme rreth funksionimit të Benit në kontekstin e klasës dhe shkollës. Këto informata, mësuesja - në bashkëpunim me shërbimin psiko-pedagogjik te shkollës - i ka dokumentuar në formularin e raportimit nga shkolla, të cilin, me aprovimin e prindit të Benit, do ta ndajë me Ekipin Vlerësues komunal për të vlerësuar funksionimin pedagogjik të Benit.</p><br></br>
                <p>Në vijim, do të shohim shkurtimisht se si e ka plotësuar formularin e raportimit mësuesja.</p> 
                <p><span>Të dhënat personale të fëmijës / nxënësit</span></p>
                <p>Në faqën e parë të formularit, shkollës i kërkohet të shënoj të dhënat personale të fëmijës apo nxënësit. Ato përfshijnë:</p>
                <p>- Emrin dhe mbiemrin e fëmijës apo nxënësit; </p>
                <p>- Emrin e prindit apo kujdestarit ligjor;</p>
                <p>- Datën e lindjes së fëmijës apo nxënësit;</p>
                <p>- Gjininë e fëmijës apo nxënësit;</p>
                <p>- Adresën e vendbanimit dhe komunën ku banon fëmija apo nxënësi(ja);</p>
                <p>- Gjuhën e komunikimit të fëmijës apo nxënësit (shënim: këtu mund të përfshihet edhe gjuha e shenjave);</p>
                <p>- Shkolla dhe klasa të cilën e vijon fëmija/nxënësi(ja).</p><br></br>

                <p>Tek të dhënat personale të fëmijës apo nxënësit, shkollës i kërkohet të ofroj arsye faktike të referimit apo raportimit, duke renditur vështirësitë në pjesëmarrje dhe vështirësitë në të nxënë, me të cilat është përballuar nxënësi në shkollë.</p><br></br>

                <p>Në rastet kur fëmija apo nxënësi që referohet për vlerësim ka vijuar një shkollë apo institucion tjetër para se të regjistrohet në shkollën e tanishme, shkollës i kërkohët që tek të dhënat personale të ofroj informacione të rëndësishme që i ka marrë nga shkolla apo institucioni arsimor paraprak.</p><br></br>
                <h5 style={{textDecoration:'underline'}}>PJESA E RAPORTIMIT </h5><br></br>
                <p>Pjesa e raportimit plotësohet pas vëzhgimit dhe punës me nxënësin që bëhet brenda klasës dhe shkollës, ku përshkruhet funksionimi i nxënësit  në kontekstin e klasës dhe shkollës.</p><br></br>
                <p>Në aktivitetet e listuara në formular, në të cilat nxënësi mund të ketë më shumë vështirësi dhe që mësimdhënësi së bashku me shërbimin psiko-pedagogjik të shkollës konsiderojnë që është e nevojshme të bëhet një vlerësim pedagogjik, bëhet përshkrimi i shkurtër i performancës së nxënësit.</p><br></br>
                <p>Nëse p.sh. aktiviteti 9  <span>“Përvetësimi i koncepteve”</span> (apo cilido nga aktivitetet) vlerësohet dhe përshkruhet në pjesën e raportimit, ndjekim hapat si në vijim: </p><br></br>
                <p>Fillimisht, nën kolonën <span>PËRSHKRIM I SHKURTËR I PERFORMANCËS PARA NDËRHYRJES PEDAGOGJIKE,</span> përshkruhet shkurtimisht se si performon nxënësi në kontekstin e klasës. Këtu evidentohen sjelljet dhe veprimet të cilat mësimdhënësi gjatë vëzhgimit sistematik i konsideron si të qëndrueshme dhe që përsëriten. </p><br></br>
                <p>Më pas, nën kolonën <span>NIVELI I I PERFORMANCËS,</span> mësimdhënësi vlerëson me vlera nga 0 deri në 4, siaps listës së cilësorëve për secilin nivel. Udhëzimet dhe lista e cilësorëv gjendet brenda dokumentit të formularit të raportimit.</p><br></br>
                <p>Tutje, nën kolonën <span>LEHTESUESIT,</span> listohen faktorët mjedisor të cilët mësimdhënësi ka vërejt që kanë ndikim pozitiv apo përmirësojnë performancën e nxënësit në klasë.</p><br></br>
                <p>Ngjashëm, nën kolonën <span>PENGESAT</span>, listohen faktorët mjedisor të cilët mësimdhënësi ka vërejt që kanë ndikuar negativisht dhe kanë vështirësuar performancën e  nxënësit në realizimin e aktiviteteteve / detyrave.</p><br></br>

                <p>Pasi të plotësohet Pjesa e Raportimit, me aprovimin e prindit apo kujdestarit ligjor të nxënësit,Formulari i Raportimit i dorëzohet Ekipit Vlerësues Komunal për vlerësimin pedagogjik të funksionimit të nxënësit. Të dhënat e ofruara në formularin e raportimit nga ana e shkollës, ndihmojnë shumë Ekipin Vlerësues komunal, meqenëse ata e kanë të pamundur të vëzhgojnë drejtpërdrejtë nxënësin sistematikisht për një përiudhë të caktuar.</p><br></br>
                <p>Pasi që shkolla pranon Rekomandimet Vepruese nga Ekipi Vlerësues, mësimdhënësi dhe stafi i shkollës planifikojnë dhe fillojnë të zbatojnë veprimet e rekomanduara nga ana e ekipit vlerësues komunal. Rekomandimet e ofruara nga Ekipi Vlerësues mund të shërbejnë si burim shumë i vlefshëm edhe për hartimin e Planit Individual të Arsimit të nxënësit.</p><br></br>

                <h5 style={{textDecoration:'underline'}}>PJESA E MONITORIMIT</h5><br></br>
                <p>Gjatë zbatimit të rekomandimeve të Ekipit Vlerësues komunal është e rëndësishme të dokumentohet funksionimi i nxënësit në aktivitetet për të cilat është raportuar fillimisht. Kjo bëhet nën Pjesën e Monitorimit dhe do të mundesoj të evidentohet përmirësimi i funksionimit të nxënësit në klasë pas ndërhyrjes pedagogjike.</p><br></br>
                <p>Së pari, nën kolonën <span>PËRSHKRIM I SHKURTËR I PERFORMANCËS PAS NDËRHYRJES PEDAGOGJIKE,</span> në krahasim me përshkrimin që është bërë në pjesën  e raportimit përshkruhet se si performon tani nxënësi, pasi që mësimdhënësi ka filluar të zbatojë rekomandimet nga Ekipi Vlerësues komunal. </p><br></br>
                <p>Tutje, nën kolonën <span>NIVELI I I PERFORMANCËS,</span> vlerësohet performanc me numrat nga 0 deri në 4, sipas listës së cilësorëve për secilin nivel.  </p><br></br>
                <p>Në fund, nën kolonën <span>PËRSHKRIMI I PUNËS ME LEHTËSUES DHE PENGESA NË SHKOLLË,</span> përshkruhet se çfarë është ndërmarrë nga mësimdhënësi dhe stafi i shkollës për të përmirësuar performancën e nxënësit bazuar në rekomandimet e ekipit vlerësues komunal. Mund të përfshihen  format dhe teknikat e punës, materialet didaktike që janë përdorur, përshtatja e ambientit, teknologjia ndihmëse, etj. </p><br></br>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 list-container">
                <p><span>Të dhënat personale të fëmijës / nxënësit</span></p>
                <div className="list-subcontainer">
                    <p>Në faqën e parë të formularit, shkollës i kërkohet të shënoj të dhënat personale të fëmijës / nxënësit. Ato përfshijnë:</p>
                    <ul>
                        <li>
                            <p>Emrin dhe mbiemrin e fëmijës / nxënësit;</p>
                        </li>
                        <li>
                            <p>Emrin e prindit / kujdestarit ligjor;</p>
                        </li>
                        <li>
                            <p>Datën e lindjes së fëmijës / nxënësit;</p>
                        </li>
                        <li>
                            <p>Gjininë e fëmijës / nxënësit;</p>
                        </li>
                        <li>
                            <p>Adresën e vendbanimit dhe komunën ku banon fëmija / nxënësi(ja);</p>
                        </li>
                        <li>
                            <p>Gjuhën e komunikimit të fëmijës / nxënësit (<span>shënim:</span> këtu mund të përfshihet edhe gjuha e shenjave);</p>
                        </li>
                        <li>
                            <p>Shkolla dhe klasa të cilën e vijon fëmija / nxënësi(ja).</p>
                        </li>
                    </ul>
                    <p>Tek të dhënat personale të fëmijës / nxënësit, shkollës i kërkohet të ofroj arsye faktike të referimit / raportimit, duke renditur vështirësitë
                    në pjesëmarrje dhe vështirësitë në të nxënë, me të cilat është përballuar fëmija / nxënësi(ja) në shkollë.
                    </p>
                    <p>Në rastet kur fëmija / nxënësi(ja) që referohet për vlerësim ka vijuar një shkollë apo institucion tjetër para se të regjistrohet në shkollën e tanishme, 
                    shkollës i kërkohët që tek të dhënat personale të ofroj informacione të rëndësishme që i ka marrë nga shkolla apo institucioni arsimor paraprak.</p>
                </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="kid-personal-info-img"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 content-card-container" id="content-card-container-lightorange">
                <p>Formulari i raportimit nga shkolla dhe raporti i monitorimit përbëhet nga dy pjesë:</p>
                <div className="list">
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text">Pjesa e raportit që shkolla i'a dërgon Ekipit vlerësues komunal, dhe</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p>Raporti i monitorimit që shkolla, pas një kohe të caktuar dhe pas ndërhyrjes didaktike e arsimore të nxitur në shkollë, i'a dërgon Ekipit vlerësues.</p>
                    </div>
                </div>
            </div>

            <p className="col-lg-10 col-md-12 col-sm-12"> Pra, të dyja këto pjesë janë nën përgjegjësinë e shkollës dhe do t’i dërgohen Ekipit vlerësues Komunal.</p>

            <h1 className='col-lg-10 col-md-12 col-sm-12'>Formulari i raportimit nga shkolla</h1>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image1"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 list-activity-paragraph-container">
                <p>Në listën e aktiviteteve
                <span className='red-light-span'> [Kolona:</span> 
                <span className='red-bold-span'>Lista e Aktiviteteve</span> 
                <span className='red-light-span'>] </span>
                janë identifikuar aktivitetet e KNF-së që lidhen thjesht me planin shkollor dhe që kanë të bëjnë me të nxënit, 
                aplikimin e njohurive të fituara, të menduarit, zgjidhjen e problemeve dhe marrjen e vendimeve (kapitulli 1 i KNF-së) dhe pjesëmarrja për një total nga 21 aktivitete.</p>
                <br />
                <p>Në KNF me aktivitet nënkuptohet: <span>“ekzekutimi i një detyre ose i një veprimi nga një individ. Kjo përfaqëson perspektivën individuale të funksionimit”</span> dhe pjesëmarrja është:
                    <span>“përfshirja e një personi në një situatë të jetës”</span>.
                </p>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 list-container">
                <p><span>Lista e aktiviteteve</span></p>
                <div className="list-subcontainer">
                    <ol>
                        <li>
                            <p>Shikimi;</p>
                        </li>
                        <li>
                            <p>Dëgjimi;</p>
                        </li>
                        <li>
                            <p>Shqisa të tjera të qëllimshme;</p>
                        </li>
                        <li>
                            <p>Kopjimi;</p>
                        </li>
                        <li>
                            <p>Të nxënit përmes veprimeve me objekte;</p>
                        </li>
                        <li>
                            <p>Përvetësimi i informacionit;</p>
                        </li>
                        <li>
                            <p>Përvetësimi i gjuhës;</p>
                        </li>
                        <li>
                            <p>Përsëritja / bërja e disa provave;</p>
                        </li>
                        <li>
                            <p>Përvetësimi i koncepteve;</p>
                        </li>
                        <li>
                            <p>Mësimi i leximit;</p>
                        </li>
                        <li>
                            <p>Leximi;</p>
                        </li>
                        <li>
                            <p>Mësimi i shkrimit;</p>
                        </li>
                        <li>
                            <p>Shkrimi;</p>
                        </li>
                        <li>
                            <p>Mësimi i përllogaritjeve;</p>
                        </li>
                        <li>
                            <p>Përllogaritja dhe zgjidhja e problemeve;</p>
                        </li>
                        <li>
                            <p>Përvetësimi i shkathtësive;</p>
                        </li>
                        <li>
                            <p>Përqëndrimi i vëmendjes;</p>
                        </li>
                        <li>
                            <p>Drejtimi i vëmendjes;</p>
                        </li>
                        <li>
                            <p>Loja;</p>
                        </li>
                        <li>
                            <p>Marrja e vendimeve;</p>
                        </li>
                        <li>
                            <p>Ndërveprimet ndërpersonale dhe marrëdhëniet me bashkëmoshatarët dhe mësimdhënësit.</p>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image2"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p>
                Në këtë rast, pas një periudhe vëzhgimi, mësimdhënësit u kërkohet të përshkruajnë shkurtimisht ato aktivitete në të cilat nxënësi(ja) ka vështirësi[V1] ,
                pa harruar të tregojë edhe ato aktivitete që mund të përbëjnë një burim pozitiv të funksionimit të nxënësve (prandaj nuk është e nevojshme të plotësohet
                tabela e raportimit për të gjitha aktivitetet e listuara, por vetëm për ato që konsiderohen të dobishme për raportim) <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Përshkrim i shkurtër i performancës para ndërhyrjes didaktike</span>].</span>
                </p>
                <br />
                <p>
                    Aktivitete të tilla cilësohen edhe përmes një <span>niveli të performancës</span> (nga 0 = pa asnjë vështirësi, deri në 4 = vështirësi e plotë) <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Niveli i performancës</span>].</span> Kjo në KNF kuptohet si 
                “konstruksion që përshkruan atë që bën individi në mjedisin e tij aktual/real (pra në këtë rast ai i shkollës) dhe, për rrjedhojë, prezanton aspektin e përfshirjes së një personi në situata jetësore”.
                </p>
                <br />
                <p>
                Performanca është një konstruksion që përshkruan atë që bën individi në mjedisin e tij aktual/real (pra në këtë rast ai i shkollës) dhe, për rrjedhojë, prezanton aspektin e përfshirjes së një personi në situata jetësore. 
                Kjo është në marrëdhënie të ngushtë me faktorët mjedisorë.
                </p>
                <p>[V1]Ishte e shkruar « has vështirësi », pra gjysmë anglisht gjysmë shqip. E modifikova. Nëse duhet modifikim edhe në platformen E-Learning ?</p>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 lists-container">
                <div className="paragraph-list-container">
                    <p><span>Lista e cilësorëve</span></p>
                    <p>0 = Asnjë vështirësi </p>
                    <p>1 = Vështirësi e lehtë</p>
                    <p>2 = Vështirësi mesatare </p>
                    <p>3 = Vështirësi e rëndë</p>
                    <p>4 = Vështirësi e plotë </p>
                </div>
                <div className="paragraph-list-container">
                    <p><span>Niveli i performancës</span></p>
                    <p>(nuk ka, mungon, e papërfillshme) </p>
                    <p>(e pakët, e ulët…)</p>
                    <p>(mesatare, e konsiderueshme…)</p>
                    <p>(e lartë, ekstreme….)</p>
                    <p>(totale….)</p>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image3"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p>
                    Kështu që, një mjedis i tillë kodifikohet duke i'u referuar lehtësuesve dhe pengesave të pranishme në planin shkollor dhe të cilat janë në bashkëveprim me aktivitetet që synohen të kodifikohen.
                </p>
                <br />
                <p>
                    <span>Lehtësuesit</span> janë “faktorë që, përmes pranisë së tyre, përmirësojnë funksionimin e fëmijës dhe ulin paaftësinë” <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Lehtësuesit</span>]</span>. Këta përfshijnë aspekte të tilla si, mjedisi fizik i qasshëm, disponueshmëria e teknologjisë përkatëse asistuese ose ndihmuese dhe qëndrimet pozitive të njerëzve ndaj aftësisë së kufizuar. 
                    Gjithashtu përfshijnë shërbime, sisteme dhe politika që kanë për qëllim rritjen e përfshirjes së të gjithë personave me gjendje shëndetësore të caktuara në të gjitha fushat e jetës.
                </p>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 content-card-container" id="content-card-container-rose">
                <p>Lehtësuesit janë faktorë mjedisorë që, përmes prezencës së tyre, përmirësojnë funksionimin dhe ulin paaftësinë.</p>
                <p>Këta përfshijnë:</p>
                <div className="list">
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text"><span>Produkte dhe teknologji:</span> natyrore ose të bëra nga njeriu, mjete dhe teknologji ekzistuese në ambientin rrethues të një individi, të cilat mblidhen, krijohen, prodhohen dhe fabrikohen;</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Mjedisi natyror dhe ndryshimet mjedisore të bëra nga njeriu:</span> në lidhje me elementët frymorë dhe jofrymorë të mjedisit natyror ose fizik dhe pjesëve të atij mjedisi që janë modifikuar nga njeriu;</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text"><span>Marrëdhëniet dhe mbështetja shoqërore:</span> në lidhje me personat apo kafshët që ofrojnë mbështetje konkrete fizike apo emocionale, ushqim, mbrojtje, ndihmë dhe kanë të bëjnë me marrëdhëniet me njerëzit e tjerë.</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Qëndrimet:</span> që kanë të bëjnë me pasojat e dukshme të zakoneve, praktikave, ideologjive, vlerave, normave, bindjeve racionale dhe bindjeve fetare.</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Shërbimet, sistemet dhe politikat e një komuniteti.</span></p>
                    </div>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image4"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p>Pengesat janë “faktorët në mjedisin e një personi që, përmes pranisë së tyre, kufizojnë funksionimin”.</p>
                <p><span className='red-light-span'>[Kolona: <span className='red-bold-span'>Pengesat</span>].</span></p>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 content-card-container" id="content-card-container-rose">
                <p>Këto përfshijnë:</p>
                <div className="list">
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text"><span>Produkte dhe teknologji:</span> mjete dhe teknologji ekzistuese në ambientin rrethues të një individi, të cilat mblidhen, krijohen prodhohen dhe fabrikohen;</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Mjedisi natyror dhe ndryshimet mjedisore të bëra nga njeriu:</span> në lidhje me elementët frymorë dhe jofrymorë të mjedisit natyror ose fizik dhe pjesëve të atij mjedisi që janë modifikuar nga njeriu;</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text"><span>Marrëdhëniet dhe mbështetja shoqërore:</span> në lidhje me personat apo kafshët që ofrojnë mbështetje konkrete fizike apo emocionale, ushqim, mbrojtje, ndihmë dhe kanë të bëjnë me marrëdhëniet me njerëzit e tjerë.</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Qëndrimet:</span> që kanë të bëjnë me pasojat e dukshme të zakoneve, praktikave, ideologjive, vlerave, normave, bindjeve racionale dhe bindjeve fetare.</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p><span>Shërbimet, sistemet dhe politikat e një komuniteti.</span></p>
                    </div>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image5"></div>
            </div>

            <h1 className='col-lg-10 col-md-12 col-sm-12'>Raporti i monitorimit</h1>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p>
                Ky raport, plotësohet nga shkolla pasi që të jenë ndërmarrë ndërhyrje të rëndësishme arsimore në bazë të rekomandimeve vepruese për shkollën dhe familjen (formulari i tretë i instrumeteve) nga Ekipi vlerësues komunal.
                Në këtë pjesë, shkolla jep një përshkrim të shkurtër të performancës pas ndërhyrjes didaktike <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Përshkrim i shkurtër i performancës pas ndërhyrjes pedagogjike</span>]</span>.
                </p>
                <br />
                <p>
                Edhe në këtë rast shprehet një vlerë për sa i përket <span>performancës</span> <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Niveli i performancës</span>]</span> dhe përshkruhet puna e filluar, me referencë të veçantë në <span>fuqizimin e lehtësuesve</span> dhe në <span>reduktimin e pengesave</span> që lehtësojnë
                ose pengojnë nxënien dhe pjesëmarrjen e fëmijës në shkollë <span className='red-light-span'>[Kolona: <span className='red-bold-span'>Përshkrimi i punës me lehtësues dhe pengesa në shkollë</span>].</span>
                </p>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="pjesaraportimit-img image6"></div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraphcard-container">
                <p>Lëhtësuesit ne kontekstin e shkollës duhet të jenë të përshtatshme për të adresuar nevojat e tyre specifike. Këto mund të përfshijnë:</p>
                <div className="content-card-container" id="content-card-container-rose">
                    <div className="list">
                        <div className="list-item">
                            <div className="list-bullet"></div>
                            <p className="list-item-text"><span>Përdorimi i metodave mësimore të ndryshme:</span> Mësuesit duhet të përdorin një gamë të gjerë metodash mësimore për të përshtatur mësimin sipas nevojave të fëmijëve. Kjo mund të përfshijë vizualizime të shumta, aktivitete praktike, përdorim të teknologjisë dhe materialeve të përshtatshme për fëmijët me nevoja të veçanta.</p>
                        </div>
                        <div className="list-item">
                            <div className="list-bullet"></div>
                            <p><span>Ndihma individuale:</span> Fëmijët me nevoja të veçanta mund të përfitojnë nga ndihma individuale, si p.sh., ndihma e një asistenti(e) të shkollës ose e një specialisti(e) të edukimit të veçantë. Kjo ndihmë mund të ndihmojë fëmijët të përfundojnë detyrat, të marrin pjesë në aktivitete shkollore dhe të zhvillojnë aftësitë e tyre.</p>
                        </div>
                        <div className="list-item">
                            <div className="list-bullet"></div>
                            <p className="list-item-text"><span>Organizimi i kohës dhe strukturës së mësimit:</span> Është e rëndësishme të kemi organizim të qartë të kohës dhe strukturës së mësimit për fëmijët me nevoja të veçanta. Kjo mund të përfshijë vendosjen e qëllimeve të qarta, ndërprerje të shkurtra dhe ndihmë shtesë për të mbështetur pjesëmarrjen dhe angazhimin e fëmijëve.</p>
                        </div>
                        <div className="list-item">
                            <div className="list-bullet"></div>
                            <p><span>Përfshirja e prindërve dhe komunitetit:</span> Prindërit dhe komuniteti mund të jenë burime të rëndësishme për mbështetjen e fëmijëve me nevoja të veçanta në kontekstin e shkollës. Një bashkëpunim i mirëfilltë mes mësuesve, prindërve dhe specialistëve mund të ndihmojë në identifikimin e aktiviteteve të përshtatshme dhe në përmirësimin e përformancës së fëmijëve.</p>
                        </div>
                    </div>
                </div>
            </div>

            <p className='col-lg-10 col-md-12 col-sm-12'>Këto janë vetëm disa sugjerime të përgjithshme për lehtësimin e mësimnxënies dhe pjesëmarrjes në kontekstin e shkollës për fëmijët me nevoja të veçanta. Është e rëndësishme të marrim parasysh nevojat dhe karakteristikat individuale të fëmijëve për të siguruar një qasje të personalizuar dhe të përshtatshme për ta.</p>

            <h1 className="col-lg-10 col-md-12 col-sm-12">
                Periudha kur monitorohet fëmija, pas ndërhyrjes didaktike:
            </h1>

            <p className='col-lg-10 col-md-12 col-sm-12'>Plotësimi i raportit të monitorimit fillon që nga dita kur shkolla merr rekomandimet vepruese nga Ekipi vlerësues komunal. Ky proces mund të zgjasë 6 muaj, por jo më gjatë se deri kur përfundon viti shkollor aktual.</p>
        
            <h1 className="col-lg-10 col-md-12 col-sm-12">
                Si përfundim
            </h1>

            <div className="col-lg-10 col-md-12 col-sm-12 paragraph-container">
                <p>
                Mund të pohohet se qëllimet e shkollës duhet të përcaktohen duke u nisur nga personi që mëson, me origjinalitetin e rrugës së tij/saj individuale dhe hapjet e ofruara nga rrjeti i marrëdhënieve që e lidhin atë me familjen dhe me fushat shoqërore. Përcaktimi dhe zbatimi i strategjive arsimore dhe didaktike duhet të llogarisë gjithmonë veçantinë dhe kompleksitetin e secilit person, identitetin e tij/saj të artikuluar, aspiratat,
                aftësitë dhe brishtësinë e tij/saj, në fazat e ndryshme të zhvillimit dhe trajnimit.
                </p>
                <br />
                <p>
                    Në këtë këndvështrim, mësimdhënësit do të duhet të mendojnë dhe të zbatojnë projektet e tyre arsimore dhe didaktike, jo për individë abstraktë, por për njerëz që jetojnë këtu dhe tani, të cilët ngrenë pyetje specifike ekzistenciale, që kërkojnë horizonte kuptimplota. Që nga vitet e para të shkollimit është e rëndësishme që mësimdhënësit të përcaktojnë propozimet e tyre në një marrëdhënie të vazhdueshme me nevojat dhe aspiratat
                    themelore të fëmijëve dhe të adoleshentëve.
                </p>
                <br />
                <p>
                    Shkolla duhet të vendosë themelet e rrugës arsimore të fëmijëve dhe të adoleshentëve duke ditur që ajo do të vazhdojë në të gjitha fazat e mëpasshme të jetës (Projekti i jetës). Në këtë mënyrë, shkolla siguron çelësat për të mësuar, për të ndërtuar dhe për të transformuar hartat e njohurive, duke i bërë ato vazhdimisht koherente me evolucionin e shpejtë dhe shpesh të paparashikueshëm të njohurive dhe objekteve të tyre.
                </p>
                <br />
                <p>
                    Bëhet fjalë për shtjellimin e mjeteve të njohurive të nevojshme për të kuptuar kontekstet natyrore, shoqërore, kulturore, antropologjike, në të cilat nxënësit do ta gjejnë veten duke jetuar dhe punuar. Çdo individ duhet të inkurajohet që të ndjekë rrugën e vet të kompleksitetit dhe shkëmbimit, të përdorë në mënyrë efektive talentin dhe pikat e tij të fota, të kulrtivojë aktivitete që favorizojnë përvojat optimale, të marrë vendimet e tij/saj
                    përmes ushtrimit të lirisë dhe të përgjegjësisë. Siç thotë Edgar Morin, “duhet të mësojmë të jetojmë”: mësimi i rregullave të të jetuarit dhe të bashkëjetuarit është një detyrë që sot është edhe më e pashmangshme sesa në të kaluarën.
                </p>
                <br />
                <p>
                    Nga këndvështrimi arsimor kjo do të thotë që ne të gjithë kemi potencial të vendosim të jemi ata që duam dhe roli i edukimit është të lejojë aktivizimin e këtij potenciali përmes krijimit të një ambienti “lehtësues” në të cilin aktorët e përfshirë mund të jenë në gjendje të bashkë-evoluojnë në drejtim të zhvillimit pozitiv.
                </p>
            </div>

            <div className="content col-lg-10 col-md-12 col-sm-12">
                <h1>E saktë apo e pasaktë?</h1>
                <div className="list">
                    {questions.map((question, index) => (
                        <div className="list-item" key={index}>
                        <div className="list-paragraph-container">
                            <div className="list-paragraph-number">
                            <p className='number-of-paragraph'>{question.number}.</p>
                            </div>
                            <p className='content-of-paragraph'>{question.content1}</p>
                        </div>

                        {question.options.map((option) => (
                            <React.Fragment key={option.id}>
                                <input
                                    type="radio"
                                    id={option.id}
                                    name={`question${question.number}`}
                                    value={option.value}
                                    checked={selectedValues[`question${question.number}`] === option.value}
                                    onChange={() => handleToggle(`question${question.number}`, option.value)}
                                    style={{display: 'none'}}
                                />
                                <label htmlFor={option.id} className={selectedValues[`question${question.number}`] === option.value ? (option.id.endsWith('option1') ? 'check-container active' : 'stop-container active') : (option.id.endsWith('option1') ? 'check-container' : 'stop-container')} id={option.value}>
                                    {option.id.endsWith('option1') ? (
                                        <div className="check-icon icona"></div>
                                    ) : (
                                        <div className="stop-icon icona"></div>  
                                    )}
                                </label>
                            </React.Fragment>
                        ))}
                        </div>
                    ))}
                </div>
                <div className="buttons-container-mod">
                    <button disabled={sendTableValuesBtn} className={`btn-container-mod ${sendTableValuesBtn ? 'disabled' : ''}`} onClick={handleTableSubmittion}>
                        <h5 className="btn-text-mod">Dërgo</h5>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FormulariRaportimit