import rightArrow from './../../../../assets/screens/home-assets/right-arrow.svg';

export const trainingPathCardData = [
    {
        id: 1,
        title: 'Regjistrohu',
        img: rightArrow,
        alt: 'right-arrow',
        path: '',
        description: 'Pasi të keni lexuar kërkesat dhe përshkrimin e trajnimit, regjistrohuni duke klikuar \'Opsionet e regjistrimit\' (shfaqet në anën e djathtë të kësaj faqeje) dhe udhëzimet në vijim.' ,
    }
    ,
    {
        id: 2,
        title: 'Fillimi i trajnimit',
        img: rightArrow,
        alt: 'right-arrow',
        path: '/modules/moduli-1',
        description: 'Filloni trajnimin duke klikuar në vegëzën më poshtë dhe duke ndjekur udhëzimet për të filluar.' ,
    }
    ,
    {
        id: 3,
        title: 'Plotësoni trajnimin',
        img: rightArrow,
        alt: 'right-arrow',
        path: '/modules/moduli-1',
        description: 'Ndiqni strukturën e trajnimit, duke përfunduar të tre modulet. Në fund të secilit modul, kërkohët që t\'i nënshtroheni një testi në formë të kuizit. Ju duhet t\’i përgjigjeni saktë së paku 80% të pyetjeve për të kaluar në modulin pasues dhe për të përfunduar trajnimin. Ju keni mundësi që disa here t’i nënshtroheni testit nëse dështoni të arrini rezultatin e kërkuar.' ,
    }
    ,
    {
        id: 4,
        title: 'Certifikata',
        img: rightArrow,
        alt: 'right-arrow',
        path: '/myprofile',
        description: 'Pas përfundimit me sukses të programit të trajnimit, certifikata juaj do të shfaqet automatikisht në ekran. Printojeni ose fotografojeni atë për ta ruajtur në kompjuterin tuaj.' ,
    }
]