import React from 'react';
import { useParams } from 'react-router-dom';
import './Burimet.scss';
import { burimetData } from './burimetData';

function Burimet() {
  const { moduleId } = useParams();

  const handleDownload = (title) => {
    const downloadLink = `/assets/${title}.pdf`;
    const anchor = document.createElement("a");
    anchor.href = downloadLink;
    anchor.download = `${title}.pdf`;
    document.body.appendChild(anchor); 
    anchor.click();
    document.body.removeChild(anchor);
  }
  
  return (
    <div className='burimet-container container'>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="col-lg-9 col-md-9 col-sm-9 burimet-cards-container">
          {burimetData.map((data) => {
            if (data.id === moduleId) {
              return Array.isArray(data.burimet) ? data.burimet.map((burim) => (
                <div className="burimet-card-container" key={burim.id}>
                  <h4 className="burimet-card-title">{burim.title}</h4>
                  <button className="btn-container" onClick={() => {handleDownload(burim.title); console.log(burim.id, 'got clicked')}}>
                    <p className="btn-text">Shkarko</p>
                    <div className="arrow-icon-container"></div>
                  </button>
                </div>
              )) : null;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Burimet;
