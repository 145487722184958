import axios from "axios";

const fullUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/api" : process.env.REACT_APP_API;

export const api = axios.create({
  baseURL: fullUrl,
});

api.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response, // Allow successful responses through
  (error) => {
    const token = localStorage.getItem("token");
    const errorData = error.response?.data;

    if (error.response) {
      // Skip handling 401 errors if no token is present (silent fail)
      if (error.response.status === 401 && !token) {
        return Promise.reject(error); // Fail silently with no alert or redirect
      } else if (error.response.status === 401 && token) {
        // Redirect only if the user has a token and gets a 401
        window.location.href = "/";
      } else if (error.response.status === 404) {
        window.location.href = "/notfound";
      }
      
      // Log or alert only if a token is present
      if (token) {
        const errorMessage = errorData?.error?.message || errorData?.message || "An unexpected error occurred.";
        console.error("API Error:", errorMessage);
      }
    } else {
      console.error("Network Error:", error.message || "An unexpected network error occurred.");
    }
    return Promise.reject(error); // Ensure the error is still rejected for debugging
  }
);

