import React from 'react'
import './InstrumentetVleresuese.scss'

function InstrumentetVleresuese() {
  return (
    <div className='container instrumentetvleresuese-container'>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>Instrumente të bazuara në modelin biopsikosocial të KNF-së.</p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 content-card-container">
        <p>Pako e instrumenteve përbëhet nga:</p>
        <div className="list">
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text">Formulari i raportimit nga shkolla dhe raporti i monitorimit,</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>Profili i funksionimit të fëmijës,</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>
              Rekomandimet për shkollën dhe familjen.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstrumentetVleresuese