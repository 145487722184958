import { removeToken } from "../../../api/utils";
import {
  LOGIN_MESSAGE,
  LOGIN_MODAL,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_MODAL,
  VALIDATE_TOKEN,
  UPDATE_TOKEN,
  UPDATE_USER,
  UPDATE_USER_DATA,
} from "./type";

/********************
 REDUCER
 ********************/

export const initialState = {
  user: null,
  authenticated: false,
  token: null,
  isLoading: false,
  message: null,
  exp: null,
  loginModal: false,
  registerModal: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        authenticated: true,
        user: action.user,
        token: action.token,
        exp: action.exp,
      };    
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_USER:
      return {
        ...state,
        authenticated: true,
        user: action.user,
        token: action.token,
        exp: action.exp,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.token,
        exp: action.exp,
      };
    case LOGIN_MESSAGE:
      return { ...state, message: action.message };
    case LOGOUT_USER:
      removeToken();
      return {
        ...state,
        authenticated: false,
        user: null,
        token: null,
        exp: null,
        message: null,
        loginModal: true,
        registerModal: false,
      };

    case LOGIN_MODAL:
      return {
        ...state,
        loginModal: !state.loginModal,
        message: null,
      };
    case REGISTER_MODAL:
      return {
        ...state,
        registerModal: !state.registerModal,
        message: null,
      };
    default:
      return state;
  }
};

export default userReducer;