import React from 'react'
import './ErrorPage.scss'

function ErrorPage() {
  return (
    <div className='fluid-container'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 error-container">
                    <h1>Error Page</h1>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ErrorPage