import { combineReducers } from "redux";
import userReducer from "../app/auth/reducer";
import loaderReducer from "../app/loader/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  loader: loaderReducer,
});

export default rootReducer;
