import { SET_LOADER } from "./types";

/********************
 REDUCER
 ********************/

export const initialState = {
  showLoader: false,
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        showLoader: action.loader,
      };
    default:
      return state;
  }
};

export default loaderReducer;