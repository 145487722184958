import { Route, Routes } from "react-router-dom";
import Home from "../screens/Home/Home";
import ErrorPage from "../screens/ErrorPage/ErrorPage";
import ModuleTest from "../screens/ModuleTest/ModuleTest";
import Module from "../screens/Module/Module";
import ModuleClass from "../screens/ModuleClass/ModuleClass";
import ProtectedRoute from "./ProtectedRoute";
import Feedback from "../screens/Feedback/Feedback";
import PublicRoute from "./PublicRoute";
import LoginPage from "../screens/Login/Login";
import MyProfile from "../screens/MyProfile/MyProfile";
import RrethTrajnimit from "../screens/RrethTrajnimit/RrethTrajnimit";
import ConfirmEmail from "../screens/ConfirmEmail/ConfirmEmail";
import VerifyAccount from "../screens/VerifyAccount/VerifyAccount";
import ResetPassword from "../screens/ResetPassword/ResetPassword";
import ForgotPassword from "../screens/ForgotPassword/ForgotPassword";

function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route 
        path="/trajnimi-info" 
        element={
          <RrethTrajnimit />
        }
      />

      <Route path="/confirm" element={<ConfirmEmail />} />
      <Route path="/verify/account" element={<VerifyAccount />} />
      <Route path="/reset-password" element={<ForgotPassword />} />
      <Route
            path="/resetPassword/:userId/:token"
            element={
                <ResetPassword />
            }
      />
      <Route path="/*" element={<ErrorPage />} />
      <Route 
        path="/myprofile" 
        element={
          <ProtectedRoute>
            <MyProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/modules/:moduleId"
        element={
          <ProtectedRoute>
            <Module />
          </ProtectedRoute>
        }
      />
      <Route
        path="/modules/:moduleId/:moduleClass"
        element={
          <ProtectedRoute>
            <ModuleClass />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quiz/:moduleId"
        element={
          <ProtectedRoute>
            <ModuleTest />
          </ProtectedRoute>
        }
      />
      <Route
        path="/feedback"
        element={
          <ProtectedRoute>
            <Feedback />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AllRoutes;
