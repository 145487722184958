import React from 'react'
import './VleresimiPedagogjik.scss'
import { vleresimiPedagogjikData } from './vleresimiPedagogjikData'
function VleresimiPedagogjik() {
  return (
    <div className='container vleresimipedagogjik-container'>
      <div className="col-lg-10 col-md-12 col-sm-12 main-reasons-container">
        <h4>Pse na duhet t’i vlerësojmë fëmijët me nevoja të veçanta?</h4>
        <div className="main-reasons-subcontainer">
          <p>Tre nga arsyet kryesore, përfshijnë:</p>
          <div className="list">
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text">Përcaktimi i shërbimeve adekuate bazuar në nevojat e tyre;</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>Përcaktimi i resurseve dhe buxhetit;</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>Zhvillimi i potencialit të nxënësve me nevoja të veçanta sipas potencialit të tyre.</p>
          </div>
        </div>
        </div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 cards-container">
        <h3>Udhëzimi administrativ për vlerësimin pedagogjik të nxënësve me nevoja të veçanta arsimore</h3>
        <p>Ky Udhëzim Administrativ ka për qëllim të përcaktoj procedurën për vlerësimin pedagogjik të
          fëmijëve me nevoja të veçanta për t’iu mundësuar arsimim të përshtatshëm bazuar në nevojat e tyre individuale.
        </p>
        {vleresimiPedagogjikData.map((card) => {
          return(
            <div className="content-card-container" key={card.id} style={{backgroundColor: card.backgroundColor}}>
              <p>{card.cardTitle}</p>
              <div className="list">
              {card.list.map((listItem) => {
                return(
                  <div className="list-item" key={listItem.id}>
                    <div className="list-bullet"></div>
                    <p className="list-item-text">{listItem.listItem}</p>
                  </div>
                )
              })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VleresimiPedagogjik