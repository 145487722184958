import React from "react";
import "./Login.scss";
import Login from "../../components/shared/auth/Login/Login";

function LoginPage() {
  return (
    <div className="home-container fluid-container">
      <Login />
    </div>
  );
}

export default LoginPage;
