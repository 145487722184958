import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TrainingPathCard.scss";
import { setRegisterModal } from "../../../../redux/app/auth/actions";
import { useDispatch } from "react-redux";

function TrainingPathCard(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <div className="training-path-card-container">
      <div className="title-container">
        <h3 className="training-path-title">{props.title}</h3>
        {props.title === 'Regjistrohu' ?
          (
            <div onClick={() => {dispatch(setRegisterModal())}} className="training-path-link">
              <img src={props.img} alt={props.alt} />
            </div>
          )
          :
          (
            <div onClick={() => navigate(props.path)} className="training-path-link">
              <img src={props.img} alt={props.alt} />
            </div>
          )
        }
      </div>
      <p>{props.description}</p>
    </div>
  );
}

export default TrainingPathCard;
