export const setToken = (user, token, exp) => {
  const tokenExpire = Date.now() + exp * 1000; // Convert to timestamp
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user)); // Store user as a JSON string
  localStorage.setItem("exp", tokenExpire);
};

export const removeToken = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  if (localStorage.getItem("exp")) localStorage.removeItem("exp");
};

export const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user")); // Parse the JSON string
  const expTime = localStorage.getItem("exp");

  if (!token || !expTime) return { isValid: false };

  try {
    const currentTime = Date.now();
    if (expTime < currentTime) {
      removeToken();
      return { isValid: false };
    }
    return { isValid: true, user: user, token: token, expTime: expTime };
  } catch (err) {
    removeToken();
    return { isValid: false };
  }
};

export const handleApiError = (error) =>{
  // Check if there's an error response from backend
  if (error.response) {
      const errorData = error.response.data;
      
      // Look for structured error message or fall back
      const errorMessage = errorData?.error?.message || errorData?.message || "An unexpected error occurred.";
      console.error("API Error:", errorMessage);
      return errorMessage;
  } else {
      // Fallback for network errors or other unexpected issues
      console.error("Network Error:", error.message || "An unexpected network error occurred.");
      return "A network error occurred.";
  }
}