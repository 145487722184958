export const questionsData = [
    {
      number: 1,
      content1: "Diagnoza si e vetme nuk është e mjaftueshme për të përshkruar problemet e përditshme të funksionimit, që një individ me probleme shëndetësore përjeton.",
      options: [
        { id: 'question1-option1', value: 'op1.1', label: 'True' },
        { id: 'question1-option2', value: 'op1.2', label: 'False' },
      ],
      correctAnswer: 'True'
    },
    {
      number: 2,
      content1: "Dy persona me të njejtën diagnozë, mund të kenë profile mjaft të ndryshme të funksionimit, dhe rrjedhimisht - nevoja të ndryshme.",
      highlightContent: '',
      content2: '',
      options: [
        { id: 'question2-option1', value: 'op2.1', label: 'True' },
        { id: 'question2-option2', value: 'op2.2', label: 'False' },
      ],
      correctAnswer: 'True'
    },
    {
      number: 3,
      content1: "KNF nuk merr parasysh dallimet kulturore.",
      highlightContent: '',
      content2: '',
      options: [
        { id: 'question3-option1', value: 'op3.1', label: 'True' },
        { id: 'question3-option2', value: 'op3.2', label: 'False' },
      ],
      correctAnswer: 'True'
    },

    {
        number: 4,
        content1: "Sipas KNF-së, fëmijët me ",
        highlightContent: 'aftësi të kufizuara ',
        content2: 'përfshihen në kategorinë e personave me \"probleme ose pengesa në funksionimin dhe aktivitetet e zakonshme \", shkaku i sëmundjeve, çrregullimeve ose lëndimeve.',
        options: [
          { id: 'question4-option1', value: 'op4.1', label: 'True' },
          { id: 'question4-option2', value: 'op4.2', label: 'False' },
        ],
        correctAnswer: 'True'
      },

      {
        number: 5,
        content1: "",
        highlightContent: 'Faktorët personal ',
        content2: 'mund të përfshijnë, por nuk limitohen me: moshën, gjininë, sistemet e ballafaqimit.',
        options: [
          { id: 'question5-option1', value: 'op5.1', label: 'True' },
          { id: 'question5-option2', value: 'op5.2', label: 'False' },
        ],
        correctAnswer: 'True'
      },

      {
        number: 6,
        content1: "Niveli i funksionimit të një personi nuk ndryshon kurrë.",
        highlightContent: '',
        content2: '',
        options: [
          { id: 'question6-option1', value: 'op6.1', label: 'True' },
          { id: 'question6-option2', value: 'op6.2', label: 'False' },
        ],
        correctAnswer: 'False'
      },
      {
        number: 7,
        content1: "KNF mund të krijojë një ",
        highlightContent: 'gjuhë të përbashkët ',
        content2: 'ndërmjet një personi dhe një profesionisti shëndetësor.',
        options: [
          { id: 'question7-option1', value: 'op7.1', label: 'True' },
          { id: 'question7-option2', value: 'op7.2', label: 'False' },
        ],
        correctAnswer: 'True'
      },
      {
        number: 8,
        content1: "",
        highlightContent: 'Funksionet trupore ',
        content2: 'përfshijnë pjesët anatomike siç janë: organet, gjymtyrët dhe komponentet e tyre.',
        options: [
          { id: 'question8-option1', value: 'op8.1', label: 'True' },
          { id: 'question8-option2', value: 'op8.2', label: 'False' },
        ],
        correctAnswer: 'False'
      },
      {
        number: 9,
        content1: "Funksionimi dhe aftësia e kufizuar reflektojnë ndërveprimin ndërmjet ",
        highlightContent: 'gjendjes shëndetësore, faktorëve mjedisor dhe atyre personal.',
        content2: '',
        options: [
          { id: 'question9-option1', value: 'op9.1', label: 'True' },
          { id: 'question9-option2', value: 'op9.2', label: 'False' },
        ],
        correctAnswer: 'True'
      },
    // Add more questions...
  ];