export const moduleClassContentData = [
  {
    id: "moduli-1",
    contentData: [
      {
        id: 1,
        title: "Arsimi gjithëpërfshirës",
        path: "arsimi-gjithëpërfshirës",
        nextLinkBtn: "/modules/moduli-1/pse-arsimi-gjithëpërfshirës",
        backLinkBtn: "/modules/1",
      },
      {
        id: 2,
        title: "Pse duhet arsimi të jetë gjithëpërfshirës?",
        path: "pse-arsimi-gjithëpërfshirës",
        nextLinkBtn: "/modules/moduli-1/dëmtimet&aftësia-kufizuar",
        backLinkBtn: "/modules/moduli-1/arsimi-gjithëpërfshirës",
      },
      {
        id: 3,
        title: "Dëmtimet & aftësia e kufizuar",
        path: "dëmtimet&aftësia-kufizuar",
        nextLinkBtn: "/modules/moduli-1/dëmtimet-tek-individët",
        backLinkBtn: "/modules/moduli-1/pse-arsimi-gjithëpërfshirës",
      },
      {
        id: 4,
        title: "Si ndikojnë dëmtimet tek individët?",
        path: "dëmtimet-tek-individët",
        nextLinkBtn: "/modules/moduli-1/çfarë-është-aftësia-kufizuar",
        backLinkBtn: "/modules/moduli-1/dëmtimet&aftësia-kufizuar",
      },
      {
        id: 5,
        title: "Çfarë është aftësia e kufizuar?",
        path: "çfarë-është-aftësia-kufizuar",
        nextLinkBtn: "/modules/moduli-1/nivelet-arsimit",
        backLinkBtn: "/modules/moduli-1/dëmtimet-tek-individët",
      },
      {
        id: 6,
        title: "Nivelet e arsimit për të gjithë",
        path: "nivelet-arsimit",
        nextLinkBtn: "/modules/moduli-1/parimet-arsimit",
        backLinkBtn: "/modules/moduli-1/çfarë-është-aftësia-kufizuar",
      },
      {
        id: 7,
        title: "Parimet e arsimit gjithëpërfshirës",
        path: "parimet-arsimit",
        nextLinkBtn: "/modules/moduli-1/përfitimet-nga-arsimi",
        backLinkBtn: "/modules/moduli-1/nivelet-arsimit",
      },
      {
        id: 8,
        title: "Përfitimet nga arsimi gjithëpërfshirës",
        path: "përfitimet-nga-arsimi",
        nextLinkBtn: "/modules/moduli-1/ligjet-politikat-për-arsimin",
        backLinkBtn: "/modules/moduli-1/parimet-arsimit",
      },
      {
        id: 9,
        title:
          "Ligjet dhe politikat që sigurojnë arsim gjithëpërfshirës në Kosovë",
        path: "ligjet-politikat-për-arsimin",
        nextLinkBtn: "/modules/moduli-1/mekanizmat-për-gjithpërfshirje",
        backLinkBtn: "/modules/moduli-1/përfitimet-nga-arsimi",
      },
      {
        id: 10,
        title:
          "Mekanizmat mbështetës që ndihmojnë në sigurimin e gjithëpërfshirjes",
        path: "mekanizmat-për-gjithpërfshirje",
        nextLinkBtn: "/modules/moduli-1/ligji-per-arsimin-parauniversitar",
        backLinkBtn: "/modules/moduli-1/ligjet-politikat-për-arsimin",
      },
      {
        id: 11,
        title: "Ligji për arsimin parauniversitar në republikën e Kosovës",
        path: "ligji-per-arsimin-parauniversitar",
        nextLinkBtn: "/modules/moduli-1/vlerësimi-pedagogjik",
        backLinkBtn: "/modules/moduli-1/mekanizmat-për-gjithpërfshirje",
      },
      {
        id: 12,
        title: "Vlerësimi pedagogjik i nxënësve me nevoja veçanta arsimore",
        path: "vlerësimi-pedagogjik",
        nextLinkBtn: "/modules/moduli-1/intrumentet-vlerësuese",
        backLinkBtn: "/modules/moduli-1/ligji-per-arsimin-parauniversitar",
      },
      {
        id: 13,
        title: "Instrumentet për vlerësimin pedagogjik",
        path: "intrumentet-vlerësuese",
        nextLinkBtn: "/quiz/moduli-1",
        backLinkBtn: "/modules/moduli-1/vlerësimi-pedagogjik",
      },
      {
        id: 14,
        title: "Burimet",
        path: "burimet",
        nextLinkBtn: "/modules/moduli-1",
        backLinkBtn: "/modules/moduli-1"
      },
    ],
    ids: {
      "arsimi-gjithëpërfshirës": 1,
      "pse-arsimi-gjithëpërfshirës": 2,
      "dëmtimet&aftësia-kufizuar": 3,
      "dëmtimet-tek-individët": 4,
      "çfarë-është-aftësia-kufizuar": 5,
      "nivelet-arsimit": 6,
      "parimet-arsimit": 7,
      "përfitimet-nga-arsimi": 8,
      "ligjet-politikat-për-arsimin": 9,
      "mekanizmat-për-gjithpërfshirje": 10,
      "ligji-per-arsimin-parauniversitar": 11,
      "vlerësimi-pedagogjik": 12,
      "intrumentet-vlerësuese": 13,
      burimet: 14,
    },
  },
  {
    id: "moduli-2",
    contentData: [
      {
        id: 1,
        title: "Përshkrimi i funksionimit",
        path: "përshkrimi-funksionimit",
        nextLinkBtn: "/modules/moduli-2/sinteza-biopsikosociale",
        backLinkBtn: "/modules/2",
      },
      {
        id: 2,
        title: "Sinteza biopsikosociale",
        path: "sinteza-biopsikosociale",
        nextLinkBtn: "/modules/moduli-2/modeli-biopsikosocial",
        backLinkBtn: "/modules/moduli-2/përshkrimi-funksionimit",
      },
      {
        id: 3,
        title: "Modeli biopsikosocial i funksionimit dhe aftësisë së kufizuar",
        path: "modeli-biopsikosocial",
        nextLinkBtn:
          "/modules/moduli-2/vazhdimësia-funksionimit&aftësisë-kufizuar",
        backLinkBtn: "/modules/moduli-2/sinteza-biopsikosociale",
      },
      {
        id: 4,
        title: "Vazhdimësia ndërmjet funksionimit dhe aftësisë së kufizuar",
        path: "vazhdimësia-funksionimit&aftësisë-kufizuar",
        nextLinkBtn: "/quiz/moduli-2",
        backLinkBtn: "/modules/moduli-2/modeli-biopsikosocial",
      },
      {
        id: 5,
        title: "Burimet",
        path: "burimet",
        nextLinkBtn: "/modules/moduli-2",
        backLinkBtn: "/modules/moduli-2",
      },
    ],
    ids: {
      "përshkrimi-funksionimit": 1,
      "sinteza-biopsikosociale": 2,
      "modeli-biopsikosocial": 3,
      "vazhdimësia-funksionimit&aftësisë-kufizuar": 4,
      burimet: 5,
    },
  },
  {
    id: "moduli-3",
    contentData: [
      {
        id: 1,
        title:
          "Hyrje në instrumentet për vlerësimin pedagogjik të nxënësve me nevoja të veçanta",
        path: "hyrje-instrumentet-vlerësuese",
        nextLinkBtn: "/modules/moduli-3/formulari-raportimit",
        backLinkBtn: "/modules/3",
      },
      {
        id: 2,
        title: "Formulari i raportimit nga shkolla dhe raportin e monitorimit",
        path: "formulari-raportimit",
        nextLinkBtn: "/quiz/moduli-3",
        backLinkBtn: "/modules/moduli-3/hyrje-instrumentet-vlerësuese",
      },
      {
        id: 3,
        title: "Burimet",
        path: "burimet",
        nextLinkBtn: "/modules/moduli-3",
        backLinkBtn: "/modules/moduli-3",
      },
    ],
    ids: {
      "hyrje-instrumentet-vlerësuese": 1,
      "formulari-raportimit": 2,
      burimet: 3
    },
  },
];
