import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TestExplain.scss";

function TestExplain({updateQuizStep, title}) {
  const { moduleId } = useParams();

  const navigate = useNavigate();

  if (moduleId == 'moduli-1' || moduleId == 'moduli-2' || moduleId == 'moduli-3') {
    return (
      <div className="container testexplain-container">
        <div className="col-lg-10 col-md-12 col-sm-12 explanation-test-fill-container">
          <h3>Shpjegime për plotësimin e testeve</h3>
          <div className="list col-lg-10 col-md-12 col-sm-12">
            <div className="list-item">
              <div className="list-bullet"></div>
              <p className="list-item-text">
                Çdo test kërkon më pak se 10 minuta për të përfunduar.
              </p>
            </div>
            <div className="list-item">
              <div className="list-bullet"></div>
              <p>
                Ky është një pyetësor me shumë zgjedhje. Ju do të duhet të
                klikoni mbi përgjigjen e saktë për secilën pyetje. Numri i
                përgjigjeve të mundshme do të tregohet në kllapa për secilën
                pyetje.
              </p>
            </div>
            <div className="list-item">
              <div className="list-bullet"></div>
              <p>
                Në fund të çdo testi, duhet të klikoni « dërgoni » për të
                kontrolluar përgjigjet tuaja. Për të përfunduar modulin dhe për
                të kaluar në modulin tjetër (ose për të përfunduar të gjithë
                trajnimin), ju duhet ti përgjigjeni saktë së paku 80% të
                pyetjeve.
              </p>
            </div>
            <div className="list-item">
              <div className="list-bullet"></div>
              <p>
                Në fund të çdo moduli dhe pas përfundimit të suksesshëm të
                testit përkatës, do të keni mundësinë të shkarkoni një
                përmbledhje shpjeguese të atij moduli.
              </p>
            </div>
          </div>
        </div>
        <div className="buttons-container mt-4">
          <div className="btn-container" onClick={() => navigate(`/modules/${moduleId}`)}>
            <div className="left arrow-image-container"></div>
            <p className="btn-text">Kthehu</p>
          </div>
          <div className="btn-container" onClick={()=>updateQuizStep()}>
            <p className="btn-text" >Vazhdo</p>
            <div className="right arrow-image-container"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default TestExplain;
