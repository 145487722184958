import React from "react";
import { Field } from "formik";

const Question = ({ question, values, errors, touched, questionIndex }) => {
    const isMultipleChoice = question.questionText.endsWith("(Më shumë se një përgjigje e saktë)");
    const isSingleChoice = question.questionText.endsWith("(Vetëm një përgjigje e saktë)");
  
    const splitText = question.questionText.split(/(\([^)]+\))/);
  
    return (
      <div key={question._id} className="questions-container col-lg-10">
        <div className="question-container">
          <div className="question-number-container">
            <div className="number-container">
              <p>{questionIndex + 1}.</p>
            </div>
            <p>
              {splitText.map((part, index) => {
                if ((isMultipleChoice || isSingleChoice) && index % 2 === 1) {
                  const partsInsideParentheses = part.split('\n');
                  return (
                    <React.Fragment key={index}>
                      {partsInsideParentheses.map((partInsideParentheses, indexInsideParentheses) => (
                        <span key={indexInsideParentheses} style={{ color: "red", fontSize: "smaller" }}>
                          {indexInsideParentheses > 0 ? <br /> : <br />}{partInsideParentheses}
                        </span>
                      ))}
                    </React.Fragment>
                  );
                } else {
                  return <span key={index}>{part}</span>;
                }
              })}
            </p>
          </div>
          <div className="options-container">
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="option-container">
                {isMultipleChoice ? (
                  <Field
                    type="checkbox"
                    name={question._id}
                    id={`${question._id}-${optionIndex}`}
                    value={option}
                    style={{ display: "none" }}
                  />
                ) : (
                  <Field
                    type="radio"
                    name={question._id}
                    id={`${question._id}-${optionIndex}`}
                    value={option}
                    style={{ display: "none" }}
                  />
                )}
                <label
                  htmlFor={`${question._id}-${optionIndex}`}
                  className={`input-${isMultipleChoice ? "checkbox" : "radio"}-displayed ${
                    values[question._id] && values[question._id].includes(option) ? "input-active" : ""
                  }`}
                ></label>
                <label className="option-label" htmlFor={`${question._id}-${optionIndex}`}>
                  {option}
                </label>
              </div>
            ))}
            {errors[question._id] && touched[question._id] && (
              <p className="error-msg">{errors[question._id]}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  export default Question;