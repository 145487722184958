import React from 'react';
import { trainingPathCardData } from '../../components/screens/Home/TrainingPathCard/trainingPathCardData';
import './RrethTrajnimit.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterModal } from '../../redux/app/auth/actions';
import TrainingPathCard from '../../components/screens/Home/TrainingPathCard/TrainingPathCard';

function RrethTrajnimit() {
  const dispatch = useDispatch();
  const { registerModal, authenticated, message } = useSelector(
    (state) => state?.user
  );

  return (
    <div className='rrethtrajnimit-container fluid-container'>
      <div className='fluid-container banner-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 banner-subcontainer'>
              <div className='banner-left col-lg-5 col-md-5 col-sm-10'>
                <h1 className='banner-title'>
                  Trajnimi për KNF (Klasifikimi Ndërkombëtar i Funksionimit)
                </h1>
                <h2 className='banner-subtitle'>
                  Përdorimi i instrumenteve për vlerësimin pedagogjik të
                  nxënësve me nevoja të veçanta
                </h2>
                <p className='banner-description'>
                  Programi i trajnimit Klasifikimi Ndërkombëtar të Funksionimit
                  dhe përdorimi i instrumenteve për vlerësimin pedagogjik të
                  nxënësve me nevoja të veçanta është i ndarë në tre module. Pas
                  përfundimit të tre moduleve, shfaqet një test në formë të
                  kuizit. Ju duhet t’i përgjigjeni saktë së paku 80% të pyetjeve
                  për të kaluar në modulin pasues dhe për të përfunduar
                  trajnimin.
                </p>
                {!authenticated && (
                  <div
                    className='banner-register-btn-container'
                    onClick={() => {
                      dispatch(setRegisterModal());
                    }}
                  >
                    <div className='banner-register-btn'>
                      <p>Regjistrohu</p>
                    </div>
                  </div>
                )}
              </div>
              <div className='banner-right col-lg-6 col-md-7 col-sm-10'>
                <div className='banner-image-container'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row training-path-container'>
          <div className='col-lg-12 col-md-12 col-sm-12 training-path-subcontainer'>
            <div className='training-path'>
              <h1 className='title'>Si të përfundoni me sukses trajnimin:</h1>
              {trainingPathCardData.map((card) => {
                return (
                  <div key={card.id}>
                    <TrainingPathCard
                      title={card.title}
                      path={card.path}
                      description={card.description}
                      img={card.img}
                      alt={card.alt}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RrethTrajnimit;
