/********************
 ACTIONS
 ********************/
import {
  LOGIN_MESSAGE,
  LOGIN_USER,
  LOGIN_USER_DISPATCHER,
  LOGOUT_USER,
  UPDATE_TOKEN,
  UPDATE_USER,
  VALIDATE_TOKEN,
  LOGIN_MODAL,
  REGISTER_MODAL,
  REGISTER_USER,
  REGISTER_USER_DISPATCHER,
  UPDATE_USER_DATA,
} from "./type";

export const setLoginModal = () => ({
  type: LOGIN_MODAL,
});

export const setRegisterModal = () => ({
  type: REGISTER_MODAL,
});

export const setRegisterUser = (values) => ({
  type: REGISTER_USER,
  values,
});

export const setRegisterDispatcher = (values) => ({
  type: REGISTER_USER_DISPATCHER,
  values,
});

export const setLoginUser = (user, token) => ({
  type: LOGIN_USER,
  user,
  token,
});

export const setLoginDispatcher = (values) => ({
  type: LOGIN_USER_DISPATCHER,
  values,
});



export const setLoginMessage = (message) => ({
  type: LOGIN_MESSAGE,
  message,
});

export const setLogout = () => ({
  type: LOGOUT_USER,
});

export const updateUser = (token, user, exp) => ({
  type: UPDATE_USER,
  token,
  user,
  exp,
});
export const updateUserData = (user) => ({
  type: UPDATE_USER_DATA,
  user,
});
export const updateToken = (token, exp) => ({
  type: UPDATE_TOKEN,
  token,
  exp,
});

export const validateToken = (token, user, exp) => ({
  type: VALIDATE_TOKEN,
  token,
  user,
  exp,
});
