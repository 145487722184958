import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./../../screens/Feedback/Feedback.scss";
import FeedbackForm from './../../components/screens/Feedback/FeedbackForm'
import Loader from "../../components/shared/Loader/Loader";

function Feedback() {
  // Validation schema for the feedback form
  const [loading, setLoading] = useState(false);

  // Callback function to update loading state
  const updateLoading = (status) => {
    setLoading(status);
  };
  const FeedbackValidation = Yup.object().shape({
    // Define validation rules for each radio group here
    question1: Yup.string().required("Please select an option"),
    question2: Yup.string().required("Please select an option"),
  });

  return (
    <>
    {!loading ? (
      <div className="feedback-container fluid-container">
        <div className="feedback-banner-container fluid-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 feedback-banner-subcontainer">
                <div className="feedback-banner-left col-lg-8 col-md-8 col-sm-8">
                  <h1 className="feedback-banner-title">
                    Vlerësoni arritjen tuaj gjatë trajnimit
                  </h1>
                  <p className="feedback-banner-description">
                    Ju lutem, përgjigjuni në pyetjet e mëposhtme
                  </p>
                  <div className="feedback-banner-time">
                    <div className="clock-img"></div>
                    <p>Rreth 50 minuta</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="answer-container container mt-5 mb-5">
          <FeedbackForm updateLoading={updateLoading} />
        </div>
      </div>
      ) : (
        <Loader />
      )     
    }
    </>
  );
}

export default Feedback;
