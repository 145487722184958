import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './ModeliBiopsikosocial.scss';
import { questionsData } from './questionsData'
import { api } from '../../../../../api/axios';
import { toast } from 'react-toastify';

// Drag and drop items
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableItem = ({ id, text }) => {
    const [, drag] = useDrag({
        type: 'ELEMENT',
        item: { id, text },
    });

    return (
        <div ref={drag} className="draggable-item">
            <p>{text}</p>
        </div>
    );
};

const DroppableInput = ({ onDrop, id, value }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: (item) => onDrop(item.text, id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} className={`droppable-input ${isOver ? 'is-over' : ''}`}>
            <input id={id} className='sm-input' value={value || ''} readOnly />
        </div>
      );
};

function ModeliBiopsikosocial() {

    const { moduleId, moduleClass } = useParams();
    const [userId, setUserId] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [dragDropValues, setDragDropValues] = useState({});
    const [sendTableValuesBtn, setSendTableValuesBtn] = useState(true);
    const [sendDragDropValuesBtn, setSendDragDropValuesBtn] = useState(true);

    const handleToggle = (questionId, value) => {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [questionId]: value,
      }));
    };

    const handleDrop = (text, inputId) => {
        const inputElement = document.getElementById(inputId);
        if (inputElement) {
            inputElement.value = text;
            setDragDropValues(prev => ({ ...prev, [inputId]: text }));
        }
    };
    
    async function fetchUserData() {
        try {
            const data = await api.get(`user/myDetails`);
            setUserId(data.data.data._id); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function fetchDragDropValues(){
        if (!userId) {
          console.error('UserId is null or undefined');
          setSendDragDropValuesBtn(false);
          return;
        }
        try {
          const response = await api.get(`miniquiz/${userId}/${moduleId}/${moduleClass}/DragDrop`);
          const fetchedValues = response.data?.data?.answerOptions;
    
          const newDragDropValues = {};
          fetchedValues.forEach((value, index) => {
              newDragDropValues[`input${index + 1}`] = value;
          });
          setDragDropValues(newDragDropValues);
          setSendDragDropValuesBtn(fetchedValues && fetchedValues.length > 0);
        } catch (error) {
          console.log('there was an error',error.message);
          setSendDragDropValuesBtn(false);
        }
    }

    async function fetchTableValues() {
        if (!userId) {
            console.error('UserId is null or undefined');
            sendTableValuesBtn(false);
            return;
        }
        try {
            const fetchAnswer = await api.get(`miniquiz/${userId}/${moduleId}/${moduleClass}/Table`);
            const fetchedAnswers = fetchAnswer.data.data?.answerOptions;
            const newSelectedValues = {};
            questionsData.forEach((question, index) => {
                newSelectedValues[`question${question.number}`] = fetchedAnswers[index] === 'True' ? question.options[0].value : question.options[1].value;
            });
            setSelectedValues(newSelectedValues);
            setSendTableValuesBtn(fetchedAnswers && fetchedAnswers.length > 0)
        } catch (error) {
            console.error('Error:', error);
            setSendTableValuesBtn(false);
        }
    }

    async function handleDragDropSubmittion() {
        const correctAnswers = {
            input1: "funksionimi",
            input2: "aftësia e kufizuar",
            input3: "funksionet",
            input4: "gjendjes shëndetësore",
            input5: "faktorëve",
            input6: "faktorëve"
        };
    
        const wrongInputs = Object.keys(correctAnswers).filter(inputId => {
            return dragDropValues[inputId] !== correctAnswers[inputId];
        }).map(inputId => inputId[inputId.length - 1]);
    
        if (wrongInputs.length > 0) {
            toast.error(`Gabim tek hapësira: ${wrongInputs.join(', ')}. Ju lutemi rregulloni përgjigjet tuaja.`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }
    
        try {
            const questionText = ["_______ dhe ________ janë terme ombrellë që përfshijnë " +
                "_______ trupore, strukturën trupore, aktivitetet dhe pjesëmarrjen si dhe janë rezultat" +
                "i ndërveprimit në mes të ______ të individit, dhe _______ mjedisor dhe _______ personal"];
            const answerOptions = Object.values(dragDropValues);
     
            const payload = {
                user: { _id: userId },
                moduleId: moduleId,
                questionSlug: moduleClass,
                quizType: 'DragDrop',
                questionText,
                answerOptions
            };

            const response = await api.post(`miniquiz/submitMiniQuiz/${moduleId}`, payload);
    
            toast.success("Përgjigjjet tuaja janë dërguar me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setSendDragDropValuesBtn(true);
        } catch (error) {
            console.error('Error on sending data', error);
            toast.error('Ndodhi një gabim gjatë dërgimit të përgjigjeve.', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    }
    

    async function handleTableSubmittion() {
        const wrongAnswers = questionsData.filter(question => {
            const selectedValue = selectedValues[`question${question.number}`];
            const isAnswerCorrect = question.options.find(option => option.value === selectedValue)?.label === question.correctAnswer;
            return !isAnswerCorrect;
        }).map(question => `Pyetja ${question.number}`);

        if (wrongAnswers.length > 0) {
            toast.error(`Gabim tek: ${wrongAnswers.join(', ')}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return; 
        }

        try {
            const questionText = [];
            const answerOptions = [];
            questionsData.forEach((question) => {
                const selectedValue = selectedValues[`question${question.number}`];
                const selectedOption = question.options.find(option => option.value === selectedValue);
                if (selectedOption) {
                    questionText.push(question.content1 + question.highlightContent + question.content2);
                    answerOptions.push(selectedOption.label); 
                }
            });
    
            const response = await api.post(`miniquiz/submitMiniQuiz/multiple/${moduleId}`, {
                user: { _id: userId },
                moduleId: moduleId,
                quizType: 'Table',
                questionSlug: moduleClass,
                questionText,
                answerOptions
            });
    
            toast.success("Pergjigja juaj eshte ruajtur me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setSendTableValuesBtn(true);
        } catch (error) {
            console.error('Error on sending data', error);
            toast.error('Ndodhi një gabim gjatë dërgimit të përgjigjeve.', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    }
    

    async function handleTableUpdate() {
        try {
            const questionText = [];
            const answerOptions = [];
            questionsData.forEach((question) => {
                const selectedValue = selectedValues[`question${question.number}`];
                const selectedOption = question.options.find(option => option.value === selectedValue);
                if (selectedOption) {
                    questionText.push(question.content1 + question.highlightContent + question.content2);
                    answerOptions.push(selectedOption.label);
                }
            });
    
            const response = await api.put(`miniquiz/${userId}/${moduleId}/${moduleClass}/multiple`, {
                user: { _id: userId },
                moduleId: moduleId,
                quizType: 'Table',
                questionSlug: moduleClass,
                questionText,
                answerOptions
            });
    
            toast.success("Pergjigjjet tuaja janë përditësuar me sukses!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
    
        } catch (error) {
            console.error('Error on updating data', error);
        }
    }

    async function handleDragDropUpdate() {
        try {
            const labels = document.querySelectorAll('.checkbox-container label');
            const questionText = Array.from(labels).map(label => label.textContent);
    
            const answerOptions = Object.values(dragDropValues);
    
            const response = await api.put(`miniquiz/${userId}/${moduleId}/${moduleClass}`, {
                user: { _id: userId },
                moduleId: moduleId,
                questionSlug: moduleClass,
                quizType: 'DragDrop',
                questionText,
                answerOptions
            });
    
            toast.success("Ndryshimet u ruajtën me sukses!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
        } catch (error) {
            console.error('Error on updating data', error);
        }
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        if (userId) {
            fetchDragDropValues();
            fetchTableValues();
        }
    }, [userId, moduleId, moduleClass]);

    return (
        <div className="modelibiopsikosocial-container container">
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img1" ></div>
            </div>
            <div className="modeli-container col-lg-10 col-md-12 col-sm-12">
                <p>Modeli biopsikosocial i KNF-së është zhvilluar nga Organizata Botërore e Shëndetësisë (OBSH) për të përshkruar dhe klasifikuar ndryshimet në shëndetin dhe funksionimin e individëve.
                    Ky model integron komponentët biologjik, psikologjik dhe social të ndikimit mbi shëndetin dhe aftësinë e një personi.</p>
            </div>
            <div className="modeli-knf col-lg-10 col-md-12 col-sm-12">
                <p>Modeli biopsikosocial i KNF-së përbëhet nga katër komponentë kryesorë:</p>
            </div>
            <div className="list-content col-lg-10 col-md-12 col-sm-12">
                <ol>
                    <li>
                        <span>Gjendja shëndetësore:</span> Kjo përfshin aspektet biologjike të shëndetit, duke përfshirë sëmundjet, dëmtimet fizike, defektet e trupit dhe gjendjet
                        tjera të shëndetit që ndikojnë në funksionimin e një personi.
                    </li>
                    <li>
                        <span>Funksionimi dhe aftësia:</span> Ky komponent përshkruan se si individët kryejnë detyrat dhe aktivitetet e tyre të përditshme. Përfshin aftësinë për të realizuar
                        aktivitete të zakonshme si lëvizja, komunikimi, mendimi, emocionet, dhe aftësitë sociale.
                    </li>
                    <li>
                        <span>Faktorët mjedisor:</span> Kjo përfshin ndikimin e mjedisit fizik dhe social në funksionimin dhe aftësitë e një personi. Përfshin faktorët fizikë të mjedisit si infrastruktura,
                        transporte, dhe qasjen në shërbime shëndetësore, si dhe faktorët social si marrëdhëniet me familjen, shoqërinë dhe ndikimi kulturor.
                    </li>
                    <li>
                        <span>Faktorët personal:</span> Ky komponent përfshin karakteristikat personale të individit, duke përfshirë mosha, gjinia, prirjet e natyrshme, historinë e jetës, kulturën, dhe
                        perceptimet e tyre. Këta faktorë personal ndikojnë në mënyrë të rëndësishme në mënyrën se si ndihen dhe funksionojnë njerëzit.
                    </li>
                </ol>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Modeli biopsikosocial shfrytëzohet për të vlerësuar dhe përshkruar nevojat e individëve me aftësi të kufizuara dhe për të përmirësuar qasjen ndaj tyre. Përdorimi i këtij modeli ndihmon
                    në kuptimin e ndikimit të ndryshimeve të shëndetit në funksionimin e përditshëm dhe ndërlidhjen komplekse midis faktorëve biopsikosocial.</p>
                <br />
                <p>Manualin e KNF-FR mund ta gjeni tek <Link to={`/modules/${moduleId}/burimet`}>Burimet</Link></p>
            </div>
            <h1 className='col-lg-10 col-md-12 col-sm-12 funksionimi-header'>Funksionimi dhe aftësia</h1>
            <div className="col-lg-10 col-md-12 col-sm-12 content-card-container">
                <p>Në kontekstin e fëmijëve me aftësi të kufizuara, funksionimi dhe aftësia përdoren për të vlerësuar dhe përshkruar disa aspekte kryesore të tyre, duke përfshirë:</p>
                <div className="list">
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text">Aktivitetet dhe pjesëmarrja</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p>Funksionet trupore</p>
                    </div>
                    <div className="list-item">
                        <div className="list-bullet"></div>
                        <p className="list-item-text">Strukturën trupore</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img2"></div>
            </div>
            <div className="modeli-container col-lg-10 col-md-12 col-sm-12">
                <p><span>Aktivitetet dhe pjesëmarrja</span> fokusohen në aftësinë e një individi për të kryer aktivitete të ndryshme dhe për të marrë pjesë në jetën sociale. Për fëmijët me
                    nevoja te veçanta, kjo përfshin vlerësimin e tyre në fusha si arsimi, komunikimi, loja, pjesëmarrja në aktivitete sportive, etj. Kjo ndihmon në identifikimin e nevojave
                    të tyre të veçanta dhe në zhvillimin e strategjive për të përmirësuar pjesëmarrjen e tyre në shoqëri.</p>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img3"></div>
            </div>
            <div className="modeli-container col-lg-10 col-md-12 col-sm-12">
                <p><span>Funksionet trupore </span> përfshijnë aspektet biologjike dhe fiziologjike të organizmit. Për fëmijët me nevoja të veçanta, ndihmon në vlerësimin e funksionimit
                    të sistemeve trupore, si sistemi muskuloskeletik, sistemi nervor, sistemi kardiovaskular, etj. Për shembull, një fëmije me paralizë cerebral mund të ketë kufizime në
                    lëvizje ose koordinim motorik.</p>
            </div>
            <div className="modeli-container col-lg-10 col-md-12 col-sm-12">
                <p><span>Struktura trupore </span> përfshin elementet anatomike të organizmit, si organet, mëlçia, marramendja, etj. Ky kategorizim përdoret për të vlerësuar ndryshimet
                    në strukturën trupore të fëmijëve me nevoja të veçanta, siç mund të jenë shtypja e muskujve, deformimet skeletore, ose ndonjë problem i përgjithshëm të sistemit të tyre trupor.</p>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img4"></div>
            </div>
            <div className="funksionimi-header">
                <h1>Gjendja shëndetësore</h1>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Gjendja shëndetësore është një term ombrellë e përdorur për të përshkruar gjendjen e përgjithshme të shëndetit të një individi. Sipas KNF-së, fëmijët me nevoja të
                    veçanta përfshihen në kategorinë e personave me "probleme ose pengesa në funksionimin dhe aktivitetet e zakonshme". Këto probleme ose pengesa mund të jenë shkaku
                    i sëmundjeve, çrregullimeve ose lëndimeve.</p>
            </div>
            <div className="list-content col-lg-10 col-md-12 col-sm-12">
                <ul>
                    <li>
                        <span>Sëmundja</span> është një gjendje shëndetësore e karakterizuar nga prania e një sëmundje të caktuar në organizëm, siç janë infeksionet, sëmundjet kardiovaskulare, sëmundjet e sistemit nervor, etj.
                    </li>
                    <li>
                        <span>Çrregullimi</span> është një gjendje shëndetësore e karakterizuar nga mungesa, humbja ose ndryshimi i funksioneve të zakonshme të trupit ose mendjes. Për shembull, sindromat e zhvillimit, 
                        çrregullimet e sistemit muskuloskeletor, çrregullimet e sjelljes, etj.
                    </li>
                    <li>
                        <span>Lëndimi</span> është një gjendje shëndetësore e shkaktuar nga dëmtimi fizik i trupit. Lëndimet mund të jenë rezultat i aksidenteve, goditjeve, plagëve ose ndonjë ngjarjeje tjetër që shkakton dëmtim trupor.
                    </li>
                </ul>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>KNF përpiqet të kuptojë ndikimin e këtyre gjendjeve shëndetësore në funksionimin dhe aktivitetet e përditshme të individit. Ajo ndihmon në vlerësimin dhe
                    përshkrimin e nevojave të veçanta dhe mbështetjen e duhur që duhet të ofrohet për të përmirësuar cilësinë e jetës së personave me nevoja të veçanta.</p>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img5"></div>
            </div>
            <div className="funksionimi-header">
                <h1>Faktorët mjedisor</h1>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Faktorët mjedisor përbëjnë mjedisin fizik, social dhe të qëndrimeve, në të cilin jetojnë njerëzit. Këta faktorë janë të jashtëm nga individi dhe mund të kenë
                    ndikim pozitiv ose negativ në performancën e tyre individuale si pjesëtarë të shoqërisë; në kapacitetin e individëve për të ekzekutuar veprime dhe detyra; apo në
                    funksionet ose strukturën trupore të individit.</p>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img6"></div>
            </div>
            <div className="funksionimi-header">
                <h1>Faktorët personal</h1>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Faktorët personal janë sfondi i veçantë i jetës dhe jetesës së individit, dhe përmbajnë tipare të individit që nuk janë pjesë e gjendjes shëndetësore.</p>
                <br />
                <p>Këta faktorë mund të përfshijnë: gjininë, racën, moshën, stilin e jetesës, shprehitë, stilet e ballafaqimit, sfondin social, shkollimin, profesionin, përvojat e kaluara, etj.</p>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img7"></div>
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 text-container" >
                <p><span>Mbani mend...</span></p>
                <br />
                <p>Funksionimi dhe aftësia e kufizuar janë terme ombrellë që përfshijnë funksionet trupore, strukturën trupore, aktivitetet dhe pjesëmarrjen si dhe janë rezultat i ndërveprimit
                    në mes të gjendjes shëndetësore të individit, dhe faktorëve mjedisor dhe faktorëve personal.</p>
            </div>
            <DndProvider backend={HTML5Backend}>
                <div className="plotesoni-content col-lg-10 col-md-12 col-sm-12">
                    <h1>Plotësoni tekstin duke përdorur fjalët kyçe të dhëna</h1>
                    <div className='paragraph-elements-container'>
                        <p>Tërheqeni fjalët në hapësirat e duhura.</p>
                        <div className="elements">
                            <DraggableItem className="gjendjes-shëndetësore" id="1" text="gjendjes shëndetësore" />
                            <DraggableItem id="2" text="funksionimi" />
                            <DraggableItem id="3" text="aftësia e kufizuar" />
                            <DraggableItem id="4" text="faktorëve" />
                            <DraggableItem id="5" text="funksionet" />
                            <DraggableItem id="6" text="faktorëve" />
                        </div>
                    </div>
                    <div className="drop-content">
                        <DroppableInput onDrop={handleDrop} id="input1" value={dragDropValues.input1}/>
                        <p>dhe</p>
                        <DroppableInput onDrop={handleDrop} id="input2" value={dragDropValues.input2} />
                        <p>janë terme ombrellë që përfshijnë</p>
                        <DroppableInput onDrop={handleDrop} id="input3" value={dragDropValues.input3}/>
                        <p> trupore, strukturën trupore, aktivitetet dhe pjesëmarrjen si dhe janë rezultat </p>
                        <p> i ndërveprimit në mes të</p>
                        <DroppableInput onDrop={handleDrop} id="input4" value={dragDropValues.input4}/>
                        <p>të individit, dhe</p>
                        <DroppableInput onDrop={handleDrop} id="input5" value={dragDropValues.input5}/>
                        <p>mjedisor dhe</p>
                        <DroppableInput onDrop={handleDrop} id="input6" value={dragDropValues.input6}/>
                        <p> personal</p>
                    </div>
                    <div className="buttons-container-mod">
                        <button disabled={sendDragDropValuesBtn} className={`btn-container-mod ${sendDragDropValuesBtn ? 'disabled' : ''}`} onClick={handleDragDropSubmittion}>
                            <h5 className="btn-text-mod">Dergo</h5>
                        </button>
                        {/* <div className="btn-container-mod" onClick={handleDragDropUpdate}>
                            <h5 className="btn-text-mod">Ndrysho</h5>
                        </div> */}
                    </div>
                </div>
            </DndProvider>
            <div className="col-lg-10 col-md-12 col-sm-12 img-container" >
                <div className="modelibio-img img8"></div>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Përdorimi i KNF-së në këtë kontekst ndihmon në klasifikimin e fëmijëve me nevoja të veçanta në mënyrë të saktë dhe në vlerësimin e nevojave të tyre të veçanta.
                    Në bazë të këtij vlerësimi, mund të zhvillohen strategji dhe plane veprimi për të përmirësuar cilësinë e jetës së tyre dhe për të siguruar pjesëmarrjen e tyre të
                    plotë në shkollë dhe shoqëri.</p>
            </div>
            <div className="funksionimi-header">
                <h1>KNF është model universal</h1>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>KNF është model universal dhe aplikohet tek të gjithë njerëzit pavarësisht kulturës, gjendjes shëndetësore, gjinisë, apo moshës së tyre.</p>
                <br />
                <p>Në vend që të bëj aftësinë e kufizuar si shenjë dalluese të një grupi të veçantë, KNF përshkruan të gjitha fushat e funksionimit
                    dhe aftësisë së kufizuar që janë të aplikueshme tek të gjithë njerëzit.</p>
            </div>
            <div className="funksionimi-header">
                <h1>KNF ofron një gjuhë neutrale</h1>
            </div>
            <div className="burimet-content col-lg-10 col-md-12 col-sm-12">
                <p>Definicionet e kategorive të KNF-së janë konstruktuar dhe definuar duke përdorur gjuhë neutrale, pa konotacion negativ e të papërshtatshëm.
                    Ato tregojnë aspekte shëndetësore dhe gjendje shëndetësore jo-problematike (p.sh. neutrale), nën ombrellën e termit ‘funksionim’.</p>
                <br />
                <p>Dëmtimi, kufizimi në realizimin e aktivitetit apo kufizimet në pjesëmarrje reflektojnë aspektet problematike të shëndetit dhe gjendjes
                    shëndetësore nën ombrellën e termit ‘aftësi e kufizuar’.</p>
            </div>
            <div className="table-content col-lg-10 col-md-12 col-sm-12">
                    <h1>E saktë apo e pasaktë?</h1>
                
                <div className="list">
                    {questionsData.map((question, index) => (
                        <div className="list-item" key={index}>
                        <div className="list-paragraph-container">
                            <div className="list-paragraph-number">
                            <p className='number-of-paragraph'>{question.number}.</p>
                            </div>
                            <p className='content-of-paragraph'>{question.content1}<span>{question.highlightContent}</span>{question.content2}</p>
                        </div>

                        {question.options.map((option) => (
                            <React.Fragment key={option.id}>
                                <input
                                    type="radio"
                                    id={option.id}
                                    name={`question${question.number}`}
                                    value={option.value}
                                    checked={selectedValues[`question${question.number}`] === option.value}
                                    onChange={() => handleToggle(`question${question.number}`, option.value)}
                                    style={{display: 'none'}}
                                />
                                <label htmlFor={option.id} className={selectedValues[`question${question.number}`] === option.value ? (option.id.endsWith('option1') ? 'check-container active' : 'stop-container active') : (option.id.endsWith('option1') ? 'check-container' : 'stop-container')} id={option.value}>
                                    {option.id.endsWith('option1') ? (
                                        <div className="check-icon icona"></div>
                                    ) : (
                                        <div className="stop-icon icona"></div> 
                                    )}
                                </label>
                            </React.Fragment>
                        ))}
                        </div>
                    ))}
                </div>
                </div>
                <div className="buttons-container-mod">
                    <button disabled={sendTableValuesBtn} className={`btn-container-mod ${sendTableValuesBtn ? 'disabled' : ''}`} onClick={handleTableSubmittion}>
                        <h5 className="btn-text-mod">Dërgo</h5>
                    </button>
                </div>
        </div>
    )
}

export default ModeliBiopsikosocial