import informative from './../../../../../assets/screens/module-assets/informative.svg'
import qendrimet from './../../../../../assets/screens/module-assets/qendrimet.svg'
import comunications from './../../../../../assets/screens/module-assets/comunications.svg'
import mjedisore from './../../../../../assets/screens/module-assets/mjedisore.svg'
import financiare from './../../../../../assets/screens/module-assets/coins.svg'

export const cfareEshteData = [
    {
        id: 1,
        title: 'Nga qëndrimet',
        imgUrl: qendrimet,
        width: '53.91px',
        height: '60.09px',
        alt: 'qendrimet-icon'
    }
    ,
    {
        id: 2,
        title: 'Mjedisore',
        imgUrl: mjedisore,
        width: '60px',
        height: '60px'
    }
    ,
    {
        id: 3,
        title: 'Financiare',
        imgUrl: financiare,
        width: '54.88px',
        height: '64.64px',
        alt: 'financiare-icon'
    }
    ,
    {
        id: 4,
        title: 'Të komunikimit',
        imgUrl: comunications,
        width: '57.29px',
        height: '61.74px',
        alt: 'comunications-icon'
    }
    ,
    {
        id: 5,
        title: 'Informative',
        imgUrl: informative,
        width: '52.04px',
        height: '59.99px',
        alt: 'informative-icon'
    }

]