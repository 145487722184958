import React, { useState, useEffect } from 'react'
import './ParimetArsimit.scss'
import NdaloReflekto from '../../../../shared/NdaloReflekto/NdaloReflekto'
import { useParams } from 'react-router-dom';
import { api } from '../../../../../api/axios';
import DropDownContainer from '../../../../shared/ModuleClass/Module1/DropDownContainer/DropDownContainer';

function ParimetArsimit() {
  const [userId, setUserId] = useState(null);

  const {moduleId} = useParams();
  const {moduleClass} = useParams();

  async function fetchUserData(){
    try {
      const data = await api.get('user/myDetails');
      setUserId(data.data.data._id)
    } catch (error) {
      console.log('Error on fetching user data', error);
    }
  }
  
  useEffect(() => {
    fetchUserData();
  }, [])

  return (
    <div className='container parimetarsimit-container'>
      <div className="col-lg-10 col-md-12 col-sm-12 descriptions-container">
        <p>Gjithëpërfshirja në arsim është një koncept që promovon idenë se të gjithë nxënësit,
           pavarësisht prej ndonjë ndryshimi individual, duhet të kenë mundësi të marrin pjesë në procesin 
           arsimor dhe të përfitojnë nga ai. Kjo përmban parimin se asnjë nxënës nuk duhet të mbetet pas, 
           dhe se arsimi duhet të përshtatet për t'iu përgjigjur nevojave të të gjithë nxënësve.
        </p>
        <p>Këto janë disa prej parimeve kryesore të gjithëpërfshirjes në arsim:</p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 parimetarsimit-dropdowns-container">
        <DropDownContainer
          title="Përgjithësimi i arsimit:" 
          content="Të gjithë nxënësit duhet të kenë mundësi të marrin një arsimim të mirë bazik dhe cilësor. Nuk duhet të ketë diskriminim në bazë të gjinisë, etnisë, prejardhjes sociale, besimit fetar, nevojave të veçanta ose çfarëdo ndryshimi tjetër personal."
        />
        <DropDownContainer 
          title="Përshtatja e mësimdhënies:"
          content="Metodat e mësimdhënies duhet të jenë fleksibile dhe të përshtatshme për t'i adresuar nevojat individuale të nxënësve. Kjo përfshin marrjen parasysh të stilit të mësimit, interesave, aftësive dhe nivelit të secilit nxënës."
        />
        <DropDownContainer 
          title="Mbështetja shtesë:"
          content="Nxënësit me nevoja të veçanta, siç janë ata me aftësi të kufizuara, nevoja të shëndetit mendorë, të varfër apo që janë në trajtim të veçantë, duhet të kenë qasje në mbështetje shtesë dhe të specializuar për të përfituar nga arsimi. Kjo përfshin shërbime të mësuesve mbështetës, asistentëve, profesionistëve të arsimit dhe personelit të trajnuar për t'i përgjigjur nevojave individuale të tyre."
        /> 
        <DropDownContainer 
          title="Gjithëpërfshirja sociale:"
          content="Arsimi duhet të promovojë ndërgjegjësimin dhe pranimin e ndryshimeve shoqërore. Nxënësit duhet të kenë mundësi të përballen me ndryshimet kulturore, gjuhësore dhe sociale në mjedisin shkollor, duke i promovuar pranimin, tolerancën dhe bashkëpunimin."
        />
        <DropDownContainer 
          title="Partneriteti dhe pjesëmarrja e prindërve/kujdestarëve ligjor:"
          content="Prindërit/kujdestarët ligjor dhe familjet janë partnerë të rëndësishëm në arsimin e fëmijëve. Një shkollë gjithëpërfshirëse duhet të krijojë kanale të hapura komunikimi dhe të përfshijë prindërit/kujdestarët ligjor në procesin arsimor, duke i inkurajuar ata që të jenë të përfshirë në vendimmarrjen dhe ndjekjen e përparimit të fëmijëve."
        />
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>
        Gjithëpërfshirja në arsim synon të krijojë një mjedis ku të gjithë nxënësit kanë mundësi të zhvillojnë 
        potencialet e tyre, të ndjehen të pranuar dhe të ndihen të motivuar për të mësuar.
        Kjo është një përgjegjësi e përbashkët për institucionet arsimore, mësuesit, prindërit dhe shoqërinë në tërësi për të siguruar 
        një arsimim të barabartë dhe cilësor për të gjithë.
        </p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 ndaloreflekto-container">
        <NdaloReflekto 
          userId={userId}
          moduleId={moduleId}
          questionSlug={moduleClass}
          description="Sipas jush, cilat janë përfitimet nga arsimi gjithëpërfshirës?" 
          placeholder="Bazuar ne përvojën tuaj dhe shkollën ku punoni ju, me cilin nga opsionet më lart identifikoheni ju "
        />
      </div>
    </div>
  )
}

export default ParimetArsimit