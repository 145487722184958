import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Signup.scss";
import { Formik, Form, Field, useField, ErrorMessage } from "formik";
import { object, string, ref } from "yup";
import {
  setLoginModal,
  setRegisterDispatcher,
  setRegisterModal
} from "../../../../redux/app/auth/actions";
import { useNavigate } from "react-router-dom";

function Signup() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.user);
  const [resendEmail, setResendEmail] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const RegisterValidation = object().shape({
    email: string()
      .required("Ju lutem plotesoni kete te dhene")
      .email("Ju lutem vendosni email valide"),
    firstName: string().required("Ju lutem plotesoni kete te dhene"),
    lastName: string().required("Ju lutem plotesoni kete te dhene"),
    password: string()
      .min(8, "Ju duhet te keni minimum 8 karaktere")
      .required("Ju lutem plotesoni kete te dhene"),
    repeatPassword: string().oneOf(
      [ref("password")],
      "Fjalekalimet duhet te jene te njejta!"
    ),
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div
      className="fluid-container"
      onClick={() => {
        dispatch(setRegisterModal());
      }}
    >
      <div className="container">
        <div className="row">
          <div className="signup-container">
            <div
              className="signup-form-container"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="title">Regjistrohu</h3>
              <p className="description">
                Keni një llogari?{" "} 
                <span 
                  className="login-link"
                  onClick={() => {
                    dispatch(setRegisterModal())
                    dispatch(setLoginModal())
                  }} >Kyqu</span>
              </p>
              {resendEmail &&
                <p className="description">
                  Nuk keni pranuar email konfirmimi? Kliko{" "} 
                  <span 
                    className="login-link"
                    onClick={() => {
                      dispatch(setRegisterModal())
                      navigate('/confirm')
                    }} >ketu</span>
                </p>
              }
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                }}
                onSubmit={(values) => {
                  dispatch(setRegisterDispatcher(values));
                  setResendEmail(true);
                }}
                validationSchema={RegisterValidation}
              >
                {({ errors, values }) => {
                  return (
                    <Form className="signup-form">
                      <div>
                        <div className="input-lg-container">
                          <div className="user-img"></div>
                          <Field
                            name="firstName"
                            placeholder="Emri"
                            id="firstName"
                          />
                        </div>
                        {errors.name && (
                          <p className="error-msg">{errors.name}</p>
                        )}
                      </div>
                      <div>
                        <div className="input-lg-container">
                          <div className="user-img"></div>
                          <Field
                            name="lastName"
                            placeholder="Mbiemri"
                            id="lastName"
                          />
                        </div>
                        {errors.lastName && (
                          <p className="error-msg">{errors.lastName}</p>
                        )}
                      </div>
                      <div>
                        <div className="input-lg-container">
                          <div className="mail-img"></div>
                          <Field name="email" placeholder="Emaili" id="email" />
                        </div>
                        {errors.email && (
                          <p className="error-msg">{errors.email}</p>
                        )}
                      </div>

                      <div>
                        <div className="input-lg-container">
                          <div className="lock-img" onClick={togglePasswordVisibility}></div>
                          <Field
                            name="password"
                            placeholder="Fjalekalimi"
                            id="password"
                            type={passwordVisible ? "text" : "password"}
                          />
                        </div>
                        {errors.password && (
                          <p className="error-msg">{errors.password}</p>
                        )}
                      </div>
                      <div>
                        <div className="input-lg-container">
                          <div className="lock-img" onClick={togglePasswordVisibility}></div>
                          <Field
                            name="repeatPassword"
                            placeholder="Perserit Fjalekalimi"
                            type={passwordVisible ? "text" : "password"}
                          />
                        </div>
                        {errors.repeatPassword && (
                          <p className="error-msg">{errors.repeatPassword}</p>
                        )}
                      </div>
                      {isLoading ? (
                        <div>Loading</div>
                      ) : (
                        <button
                          type="submit"
                          className="btn-container"
                        >
                          REGJISTROHU
                        </button>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;

