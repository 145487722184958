import React, { useState } from "react";
import "./ConfirmEmail.scss";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, useField, ErrorMessage } from "formik";
import { object, string } from "yup";
import { api } from "../../api/axios";

function ConfirmEmail() {
  const LoginValidation = object().shape({
    email: string()
      .required("Ju lutem plotesoni kete te dhene")
      .email("Ju lutem vendosni email valide"),
  });

  const fetchData = async (values) => {
    try {
      const response = await api.post('auth/sendActivate', {
        email: values.email
      });

    } catch (error) {
      console.error("Error sending activation email:", error);
    }
  }


  return (
    <div className=" col-lg-12 col-md-12 col-sm-12 fluid-container login-container">
      <div
        className="login-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="title">Resend email verfication</h3>
        <p className="description">
          Shkruani emailin tuaj ne kutine me poshte!
        </p>
        <Formik
          initialValues={{
            email: ""
          }}
          onSubmit={(values) => {
            fetchData(values);
          }}
          validationSchema={LoginValidation}
        >
          {({ errors, values }) => {
            return (
              <Form className="login-form">
                <div>
                  <div className="input-lg-container">
                    <div className="mail-img"></div>
                    <Field name="email" placeholder="Emaili" id="email" />
                  </div>
                  {errors.email && <p className="error-msg">{errors.email}</p>}
                </div>
                <button type="submit" className="btn-container btn-text">
                  KYQU
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ConfirmEmail;
