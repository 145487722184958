import React from 'react'
import './LigjetPolitikatPerArsimin.scss'
import { Link, useParams } from 'react-router-dom'

function LigjetPolitikatPerArsimin() {
  const { moduleId} = useParams();
  return (
    <div className='container ligjetpolitikatperarsimin-container'>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>
        Ligjet, dokumentet dhe strategjitë që sigurojnë arsimin gjithëpërfshirës në Kosovë janë të rëndësishme për të garantuar të drejtat dhe mundësitë 
        e të gjithë nxënësve, veçanërisht atyre me nevoja të veçanta arsimore. Një arsim gjithëpërfshirës do të thotë që të gjithë fëmijët, pavarësisht nga aftësitë,
        gjinia, etnia, gjuha apo statusi social, mund të marrin një arsim cilësor dhe të barabartë në një mjedis që respekton dhe promovon diversitetin.
        </p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 image-container">
        <div className="image-subcontainer"></div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>Ligjet, dokumentet dhe strategjitë që sigurojnë arsimin gjithëpërfshirës në Kosovë përfshijnë:</p>
      </div>

      <div className="col-lg-10 col-md-12 col-sm-12 ligjet-container">
        <ul>
          <li>Ligji për Arsimin Parauniversitar në Republikën e Kosovës (2011), i cili parashikon qartazi se arsimi është i obligueshëm për të gjithë fëmijët në Kosovë dhe se çdo nxënës ka të drejtën të marrë një arsim sipas nevojave dhe interesave të tij/saj;</li>
          <li>Strategjia për Arsimin 2022-2026, e cila ka si një nga objektivat kryesore zhvillimin e një sistemi arsimor gjithëpërfshirës dhe cilësor që mund të përgjigjet ndaj sfidave dhe nevojave të shoqërisë kosovare;</li>
          <li>Korniza e Kurrikulës së Kosovës (2011);</li>
          <li>Udhëzimi Administrativ 07/2012 & 16/2017 për Vlerësimin pedagogjik të nxënësve me nevoja të veçanta arsimore;</li>
          <li>Parametrat e financimit për nxënësit me nevoja të veçanta arsimore;</li>
          <li>Plani individual i arsimit;</li>
          <li>Instrumentet për vlerësimin pedagogjik të nxënësve me nevoja të veçanta arsimore.</li>
        </ul>
      </div>

      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>Dokumentet e plota mund t’i gjeni tek <Link to={`/modules/${moduleId}/burimet`}>Burimet.</Link></p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12">
        <p>Këto ligje, dokumente dhe strategji tregojnë se Kosova ka njohur rëndësinë e arsimit gjithëpërfshirës si një faktor kyç për zhvillimin social 
          dhe ekonomik të vendit dhe për respektimin e të drejtave të njeriut.
        </p>
      </div>
    </div>
  )
}

export default LigjetPolitikatPerArsimin