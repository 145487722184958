import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, Form, useNavigate } from "react-router-dom";
import { moduleClassContentData } from "../Module/moduleData";
import { useSelector, useDispatch } from "react-redux";

import "./ModuleClass.scss";
import PseArsimiGjitheperfshires from "../../components/screens/ModuleClass/Module1/PseArsimiGjitheperfshires/PseArsimiGjitheperfshires";
import ArsimiGjitheperfshires from "../../components/screens/ModuleClass/Module1/ArsimiGjitheperfshires/ArsimiGjitheperfshires";
import DemtimetAftesiaKufizuar from "../../components/screens/ModuleClass/Module1/DemtimetAftesiaKufizuar/DemtimetAftesiaKufizuar";
import DemtimetTekIndividet from "../../components/screens/ModuleClass/Module1/DemtimetTekIndividet/DemtimetTekIndividet";
import CfareEshteAftesiaKufizuar from "../../components/screens/ModuleClass/Module1/CfareEshteAftesiaKufizuar/CfareEshteAftesiaKufizuar";
import NiveletArsimit from "../../components/screens/ModuleClass/Module1/NiveletArsimit/NiveletArsimit";
import ParimetArsimit from "../../components/screens/ModuleClass/Module1/ParimetArsimit/ParimetArsimit";
import PerfitimetNgaArsimi from "../../components/screens/ModuleClass/Module1/PerfitimetNgaArsimi/PerfitimetNgaArsimi";
import LigjetPolitikatPerArsimin from "../../components/screens/ModuleClass/Module1/LigjetPolitikatPerArsimin/LigjetPolitikatPerArsimin";
import Mekanizmat from "../../components/screens/ModuleClass/Module1/Mekanizmat/Mekanizmat";
import LigjiArsimitParauniversitar from "../../components/screens/ModuleClass/Module1/LigjiArsimitParauniversitar/LigjiArsimitParauniversitar";
import VleresimiPedagogjik from "../../components/screens/ModuleClass/Module1/VleresimiPedagogjik/VleresimiPedagogjik";
import InstrumentetVleresuese from "../../components/screens/ModuleClass/Module1/InstrumentetVleresuese/InstrumentetVleresuese";
import PershkrimiFunksionimit from "../../components/screens/ModuleClass/Module2/PershkrimiFunksionimit/PershkrimiFunksionimit";
import SintezaBiopsikosociale from "../../components/screens/ModuleClass/Module2/SintezaBiopsikosociale/SintezaBiopsikosociale";
import Burimet from "../../components/shared/Burimet/Burimet";
import ModeliBiopsikosocial from "../../components/screens/ModuleClass/Module2/ModeliBiopsikosocial/ModeliBiopsikosocial";
import VazhdimesiaFunksionimit from "../../components/screens/ModuleClass/Module2/VazhdimesiaFunksionimit/VazhdimesiaFunksionimit";
import HyrjeInstrumtet from "../../components/screens/ModuleClass/Module3/HyrjeInstrumtet/HyrjeInstrumtet";
import FormulariRaportimit from "../../components/screens/ModuleClass/Module3/FormulariRaportimit/FormulariRaportimit";
import { toast } from "react-toastify";
import clockImg1 from "./../../assets/screens/module-assets/clock1.svg";
import clockImg2 from "./../../assets/screens/module-assets/clock2.svg";
import clockImg3 from "./../../assets/screens/module-assets/clock3.svg";
import { api } from "../../api/axios";
import { setLoginUser, updateUserData } from "../../redux/app/auth/actions";

function ModuleClass(props) {
  const { moduleId } = useParams();
  const { moduleClass } = useParams();
  const mounted = useRef(false);
  const mountedSlug = useRef(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [backgroundBannerColor, setBackgroundBannerColor] = useState("");
  const [clockImg, setClockImg] = useState("");
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);

  let timerId;
  const startTimer = () => {
    timerId = setTimeout(() => {
      setNextButtonDisabled(false);
    }, 0.05 * 60 * 1000); 
  };

  // const checkTimer = () => {
  //   if(!isNextButtonDisabled) {
      
  //   }
  // }

  useEffect(() => {
    startTimer(); 
    return () => clearTimeout(timerId);
  }, []);

  const handleNextButtonClick = (data) => {
    if (!isNextButtonDisabled) {
      clearTimeout(timerId);
      setNextButtonDisabled(true);
      startTimer();
      navigate(`${data.nextLinkBtn}`);
    } else {
      toast.error("Please wait for 2 minutes before continuing.");
    }
  };

  function checkModuleId(id) {
    switch (id) {
      case "moduli-1":
        setTitle("1");
        setBackgroundBannerColor("#FFF0E8");
        setClockImg(clockImg1);
        break;
      case "moduli-2":
        setTitle("2");
        setBackgroundBannerColor("#E8F3FF");
        setClockImg(clockImg2);
        break;
      case "moduli-3":
        setTitle("3");
        setBackgroundBannerColor("#FFE8F0");
        setClockImg(clockImg3);
        break;
      default:
        setBackgroundBannerColor("#FFF0E8");
    }
  }

  const matchedModuleClass = moduleClassContentData.find((moduleContent) =>
    moduleContent.contentData.some((data) => data.path === moduleClass)
  );

  const titleToDisplay = matchedModuleClass
    ? matchedModuleClass.contentData.find((data) => data.path === moduleClass)
        .title
    : "";

  const matchedModule = moduleClassContentData.find(
    (moduleContent) => moduleContent.id.toString() === moduleId
  );

  const contentToDisplay = matchedModule ? matchedModule.contentData : [];

  useEffect(() => {
    if (!mountedSlug.current) {
      mountedSlug.current = true;
      checkModuleId(moduleId);
      return;
    }
  }, [moduleId]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    updateActiveModule();
  }, [moduleClass]);

  const updateActiveModule = async () => {
    const user = await api.put("/user/lastPage", {
      slug: moduleId,
      pageName: moduleClass,
    });

    //update user in global state
    if (user.data && user.data.user) {
      dispatch(updateUserData(user.data.user, user.data.token, user.data.exp));
    }
  };

  return (
    <div className="module-class-container container-fluid">
      <div
        className="container-fluid module-class-banner-container"
        style={{ backgroundColor: backgroundBannerColor }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 module-class-banner-subcontainer">
              <div className="module-class-banner col-lg-7 col-md-7 col-sm-7">
                <h1 className="module-class-banner-title">Moduli {title}</h1>
                <p className="module-class-banner-description">
                  {titleToDisplay}
                </p>
                <div className="module-class-banner-time">
                  <div
                    className="clock-img"
                    style={{ backgroundImage: `url(${clockImg})` }}
                  ></div>
                  <p>Rreth 50 minuta</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row module-class-content-container">
          <div className="col-lg-8 col-md-12 col-sm-12 module-class-component-container">
            {(() => {
              switch (moduleId) {
                case "moduli-1":
                  switch (moduleClass) {
                    case "arsimi-gjithëpërfshirës":
                      return <ArsimiGjitheperfshires />;
                    case "pse-arsimi-gjithëpërfshirës":
                      return <PseArsimiGjitheperfshires />;
                    case "dëmtimet&aftësia-kufizuar":
                      return <DemtimetAftesiaKufizuar />;
                    case "dëmtimet-tek-individët":
                      return <DemtimetTekIndividet />;
                    case "çfarë-është-aftësia-kufizuar":
                      return <CfareEshteAftesiaKufizuar />;
                    case "nivelet-arsimit":
                      return <NiveletArsimit />;
                    case "parimet-arsimit":
                      return <ParimetArsimit />;
                    case "përfitimet-nga-arsimi":
                      return <PerfitimetNgaArsimi />;
                    case "ligjet-politikat-për-arsimin":
                      return <LigjetPolitikatPerArsimin />;
                    case "mekanizmat-për-gjithpërfshirje":
                      return <Mekanizmat />;
                    case "ligji-per-arsimin-parauniversitar":
                      return <LigjiArsimitParauniversitar />;
                    case "vlerësimi-pedagogjik":
                      return <VleresimiPedagogjik />;
                    case "intrumentet-vlerësuese":
                      return <InstrumentetVleresuese />;
                    case "burimet":
                      return <Burimet />;
                    default:
                      break;
                  }
                  break;
                case "moduli-2":
                  switch (moduleClass) {
                    case "përshkrimi-funksionimit":
                      return <PershkrimiFunksionimit />;
                    case "sinteza-biopsikosociale":
                      return <SintezaBiopsikosociale />;
                    case "modeli-biopsikosocial":
                      return <ModeliBiopsikosocial />;
                    case "vazhdimësia-funksionimit&aftësisë-kufizuar":
                      return <VazhdimesiaFunksionimit />;
                    case "burimet":
                      return <Burimet />;
                    default:
                      break;
                  }
                  break;
                case "moduli-3":
                  switch (moduleClass) {
                    case "hyrje-instrumentet-vlerësuese":
                      return <HyrjeInstrumtet />;
                    case "formulari-raportimit":
                      return <FormulariRaportimit />;
                    case "burimet":
                      return <Burimet />;
                    default:
                      break;
                  }
                  break;
                default:
                  break;
              }
            })()}

            {contentToDisplay.map((data) => {
              if (data.path === moduleClass) {
                return (
                  <div className="buttons-container" key={data.id}>
                    <div
                      className="btn-container"
                      onClick={() => {
                        navigate(`${data.backLinkBtn}`);
                      }}
                    >
                      <div className="left arrow-image-container"></div>
                      <p className="btn-text">Kthehu</p>
                    </div>
                    <button  disabled={isNextButtonDisabled}
                      className={`btn-container ${isNextButtonDisabled ? 'disabled' : ''}`}
                      onClick={() => {
                        handleNextButtonClick(data);
                      }
                      }
                    >
                      <p className="btn-text">Vazhdo</p>
                      <div className="right arrow-image-container"></div>
                    </button>
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 module-class-content-side-container">
            <h1 className="module-class-content-title">
              Përmbajtja e modulit:
            </h1>
            {contentToDisplay.map((data, index) => {
              const isActive = data.path === moduleClass;
              const isLastItem = index === contentToDisplay.length - 1;
              return (
                <div
                  className={`class-content-container ${
                    isActive ? "active" : ""
                  }`}
                  key={data.id}
                >
                  <div
                    // to={`/modules/${moduleId}/${data.path}`}
                    className={`class-content-link ${
                      isLastItem ? "last-item" : ""
                    }`}
                  >
                    {data.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleClass;
