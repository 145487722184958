import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './ResetPassword.scss';
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from 'yup';
import { api } from '../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoginModal } from '../../redux/app/auth/actions';
import { handleApiError } from '../../api/utils';

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, token } = useParams();
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const ResetPasswordValidation = yup.object().shape({
    newPassword: yup.string()
      .min(8, 'Ju duhet te keni minimum 8 karaktere')
      .required('Ju lutem plotesoni kete te dhene'),
    confirmNewPassword: yup.string()
      .oneOf([yup.ref('newPassword')], 'Fjalekalimet duhet te jene identike')
      .min(8, 'Ju duhet te keni minimum 8 karaktere')
      .required('Ju lutem plotesoni kete te dhene'),
  });

  async function resetPassword(values) {
    try {
      const cleanUserId = userId.replace(/^userId=/, '');
      const cleanToken = token.replace(/^token=/, ''); 
      const data = await api.put(`/auth/updatePassword/${cleanUserId}`, {
        password: values.confirmNewPassword,
        token: cleanToken
      });
      console.log('Password updated successfully', data);
      toast.success("Fjalekalimi juaj i ri eshte ruajtur me sukses!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate('/'); 
      dispatch(setLoginModal());
    } catch (error) {
      console.error('Error:', error?.response || error);
      handleApiError(error)
      toast.error("Fjalekalimi juaj nuk u ruajt!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 fluid-container resetPassword-container">
      <div
        className="resetPassword-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="title">Reset Password</h3>
        <Formik
          initialValues={{
              newPassword: '',
              confirmNewPassword: ''
          }}
          onSubmit={(values) => {
            resetPassword(values);
          }}
          validationSchema={ResetPasswordValidation}
        >
          {({ errors, handleSubmit }) => (
            <Form className="resetPassword-form" onSubmit={handleSubmit}>
              <div>
                <div className="input-lg-container">
                  <div className="lock-img" onClick={togglePasswordVisibility}></div>
                  <Field
                    name="newPassword"
                    placeholder="Fjalekalimi i ri"
                    id="newPassword"
                    type={passwordVisible ? "text" : "password"}
                  />
                </div>
                {errors.newPassword && (
                  <p className="error-msg">{errors.newPassword}</p>
                )}
              </div>
              <div>
                <div className="input-lg-container">
                  <div className="lock-img" onClick={togglePasswordVisibility}></div>
                  <Field
                    name="confirmNewPassword"
                    placeholder="Konfirmo fjalekalimin"
                    id="confirmNewPassword"
                    type={passwordVisible ? "text" : "password"}
                  />
                </div>
                {errors.confirmNewPassword && (
                  <p className="error-msg">{errors.confirmNewPassword}</p>
                )}
              </div>

              <button type="submit" className="btn-container btn-text">
                Reset Password
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
