import React, { useState } from 'react'
import './DropdownContent.scss'

function DropdownContent({title, content, imageSrc, }) {
    const [dropdown, setDropDown] = useState(false);

    function handleDropDown(){
        setDropDown(!dropdown);
    }

    return (
        <div className="niveletarsimit-dropdown-container">
            <div className="niveletarsimit-dropdown-subcontainer" onClick={handleDropDown}>
                <p>{title}</p>
                {!dropdown && <div className="dropdown-icon"></div>}
                {dropdown && <div className="up-icon"></div>}
            </div>
            {dropdown &&
                <div className="niveletarsimit-dropdown-option">
                <p>{content}</p>
                <div 
                    className="niveletarsimit-dropdown-option-img"
                    style={{backgroundImage: `url(${imageSrc})`}}
                ></div>
                </div>
            }
        </div>
    )
}

export default DropdownContent