import React from 'react'
import './SintezaBiopsikosociale.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Video from '../../../../shared/Video/Video';

function SintezaBiopsikosociale() {
  return (
    <div className="sinetzabiopsikosociale-container container">
      <div className="main-content-container">
        <div className="important-paragraph-container col-lg-10 col-md-12 col-sm-12">
          <p>Diagnoza si e vetme nuk është e mjaftueshme për të përshkruar problemet e përditshme të funksionimit, që një individ me probleme shëndetësore përjeton.</p>
        </div>
        <div className="content-paragraph-container col-lg-10 col-md-12 col-sm-12">
          <p>Le të shohim rastin e Anës, për të kuptuar më mirë këtë.</p>
          <br />
          <p>Ana është një vajzë shtatë vjeçare e diagnostikuar me Sindromin Down. Ajo ndjek mësimet në klasë 2-të në një shkollë fillore në Prishtinë, së bashku me
            bashkëmoshatarët e saj. Ajo komunikon verbalisht me disa fjalë por të folurit e saj është i paqartë dhe i ngadaltë për shkak të muskujve të dobët të gojës
            dhe gjuhës që e ka relativisht më të gjatë. Ajo po ashtu ka qiellzë të ngushtë dhe një zgavër të vogël në gojë. Anës i pëlqen të mësoj gjëra të reja dhe
            të përfshihet në aktivitetet mësimore, por ajo ka nevojë që gjatë procesit mësimor të ketë në dispozicion produktet dhe teknologjitë ndihmëse për procesin mësimor,
            siç mund të jenë: hardware dhe software të përshtatur, ilustrime, fotografi, tullumbace, etj. Po ashtu, është me rëndësi që edhe mësuesi i klasës të jetë i trajnuar
            si dhe të marr shërbime nga një terapist i gjuhës në mënyrë që ajo të mund t’i zhvillojë shkathtësitë e saj për të përshkruar persona, objekte, ngjarje dhe ndjenja
            me anë të fjalëve, simboleve, frazave dhe fjalive.</p>
        </div>
        <div className="content-list-container col-lg-10 col-md-12 col-sm-12">
          <p><span>Nga tregimi i Anës mund të kuptojmë:</span></p>
          <ul>
            <li>për faktorët personal të saj</li>
            <p>p.sh. Ana është një vajzë shtatë vjeçare e cila ndjek mësimet në klasë 2-të në një shkollë fillore në Prishtinë</p>
            <br />
            <li>dhe gjendjen e saj shëndetësore</li>
            <p>p.sh. Ana është e diagnostikuar me Sindromin Down</p>
            <br />
            <p><span>Tutje, nga tregimi mund të kuptojmë që Ana ka:</span></p>
            <li>dëmtime në strukturën trupore</li>
            <p>p.sh. qiellzë të ngushtë, një zgavër të vogël në gojë, gjuhë relativisht të madhe, muskujt të dobët të gojës</p>
            <br />
            <li>dhe në funksionet trupore</li>
            <p>p.sh. të folur i paqartë dhe i ngadaltë</p>
            <br />
            <li>kufizime në realizimin e aktiviteteve dhe në pjesëmarrje</li>
            <p>p.sh. vështirësi në zhvillimin e aftësisë për të përshkruar persona, objekte, ngjarje dhe ndjenja</p>
            <br />
            <li>të gjitha këto janë të ndikuara nga faktorët mjedisor</li>
            <p>p.sh. produktet dhe teknologjitë ndihmëse për proces mësimor, terapist i të folurit dhe një mësues i trajnuar</p>
          </ul>
        </div>
      </div>
      <div className="underlist-content-container col-lg-10 col-md-12 col-sm-12">
        <h1>Përtej diagnozës</h1>
        <div className="important-paragraph-container">
          <p>Dy persona me të njëjtën diagnozë, mund të kenë profile mjaft të ndryshme të funksionimit, dhe rrjedhimisht - nevoja të ndryshme.</p>
        </div>
        <p>Le të shohim në vijim se si Rina dhe Toni, që të dy të diagnostikuar me të njëjtën gjendje shëndetësore, kanë funksionim dhe nevoja të ndryshme nga njëri-tjetri.</p>
      </div>
      <Video urlSource="https://www.youtube.com/embed/4Z1Uklpc8Vw" />
      <div className="scroll-container">
        <div className="scroll-content">
          <p className='title'>Përtej diagnozës</p>
          <p>Dy persona me të njejtën diagnozë, mund të kenë profile mjaft të ndryshme të funksionimit, e rrjedhimisht edhe nevoja të ndryshme.</p><br></br>
          <p>Le të shohim në vijim se si Rina dhe Toni, që të dy të diagnostikuar me çrregullim të spektrit të autizmit, dallojnë nga njëri-tjetri si për nga funksionimi ashtu edhe për nga nevojat individuale.</p><br></br>
          <p><span>Rina</span> është nxënëse e klasës së tretë.</p> 
          <p>Ka kontakt me sy dhe për disa sekonda përcjell mimikën e personit me të cilin komunikon.</p>
          <p>Ka të zhvilluar gjuhën receptive, pra kupton gjuhën verbale që dëgjon.</p>
          <p>Zbaton udhëzimet e thjeshta verbale.</p>
          <p>Emërton objekte, fotografi, ilustrime por nuk përshkruan veprimin, cilësinë e tyre.</p>
          <p>I pëlqen shumë të pikturoj. Pikturon bukur dhe njeh shumë mirë ngjyrat.</p>
          <p>Orientohet brenda ambienti të shkollës por i duhet një përcjellës për të udhëtuar shkollë – shtëpi dhe anasjelltas.</p>
          <p>Ka vështirësi në orientimin në kohë.</p>
          <p>Ka ndjeshmëri ndaj prekjes por është hipersenzitive ndaj zhurmave dhe dritës së tepërt dhe reagon ndaj zhurmave duke mbyllur veshët dhe duke vrapuar.</p>
          <p>Identifikon shkronjat e mëdha të shtypit dhe i shkruan ato.</p>
          <p>Si dhe identifikon numrat deri në 20.</p><br></br>

          <p className='blueText'><span>Rina</span> ka nevojë për: Përshtatjen e hapësirave mësimore - eliminimin e zhurmave dhe dritës së tepërt në klasë dhe ambientet që frekuenton;</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Për fotografi e ilustrime;</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Orarin e rutinës ditore të punuar me ilustrime apo fotografi;</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Mbikëqyrës gjatë rrugës shkollë – shtëpi;</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Herë pas here edhe asistent gjatë aktiviteteve mësimore;</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Përforcues të sjelljes siç janë lëvdatat, inkurajimet.</p>
          <p className='blueText'><FontAwesomeIcon icon={faCheck} /> Kufje për zbutjen dhe eliminimin e zhurmave.</p><br></br>

          <p><span>Toni</span> është nxënës i klasës së tretë.</p><br></br>

          <p>Nuk merr pjesë në grup, nuk shfaq interesim në lojën apo aktivitetin e klasës.</p><br></br> 

          <p>Nuk ka kontakt me sy.</p>
          <p>I pëlqen loja me blloqe ndërtuese.</p>
          <p>E tërheqin topat me ngjyra.</p>
          <p>Ka sjelle të përsëritura: vazhdimisht lëviz trupin kur është ulur dhe ec në maje të gishtërinjve.</p>
          <p>Nga hyrja e shkollës arrin të shkoj deri tek klasa por jo edhe të orientohet i pavarur në hapësirat e tjera të shkollës.</p>
          <p>Vrapon pa u preokupuar për rreziqet dhe pjesën më të madhe të kohës është në lëvizje.</p>
          <p>Është në proces për të mësuar të pohojë dhe mohojë përmes ilustrimeve.</p>
          <p>Ka fare pak ndjeshmëri ndaj prekjes.</p>
          <p>Në momente të caktuara mund të godas dhe lëndoj veten.</p><br></br>

          <p className='greenText'><span>Toni</span> ka nevojë për:</p>
          <p className='greenText'>Asistent personal gjatë gjithë kohës së qëndrimit në shkollë;</p>
          <p className='greenText'><FontAwesomeIcon icon={faCheck} /> Transport për rrugën shkollë - shtëpi dhe anasjelltas;</p>
          <p className='greenText'><FontAwesomeIcon icon={faCheck} /> Përshtatjen fizike të hapësirës së mësimit (eliminimin e gjërave të forta brenda hapësirës, veshjen e mureve me material të butë);</p>
          <p className='greenText'><FontAwesomeIcon icon={faCheck} /> Jelek me peshë.</p><br></br>
          <p>Nga çfarë kuptuam, marrë parasysh dallimet individuale, është e domosdoshme që të njohim
            funksionimin e individit për të qenë në gjendje të planifikojmë mbështetjen e duhur të nxënësit.</p><br></br>
        </div>
      </div>
      <div className="undervideo-content-container col-lg-10 col-md-12 col-sm-12">
        <p>
          Nga rastet e Rinës dhe Tonit kuptuam që për të përcaktuar nevojat individuale të nxënësve, është e rëndësishme që të njohim
          funksionimin e tij/saj në një kontekst të caktuar, përtej gjendjes shëndetësore.
        </p>
      </div>
      <div className="important-paragraph-container col-lg-10 col-md-12 col-sm-12">
        <p>
          KNF ofron një kornizë për të përshkruar në mënyrë gjithëpërfshirëse profilin e funksionimit të një personi, që si këmbim ndihmon për të
          kuptuar nevojat specifike të personit.
        </p>
      </div>

      <div className="col-lg-10 col-md-12 col-sm-12 content-card-container">
        <p>Disa nga qëllimet kryesore të KNF-së, janë:</p>
        <div className="list">
          <div className="list-item">
            <div className="list-bullet"></div>
            <p className="list-item-text">Të ofrojë <span>bazë shkencore</span> për të kuptuar dhe studiuar gjendjet, rezultatet dhe përcaktuesit shëndetësor dhe që lidhen me shëndetin.</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>
              Të krijojë <span>një gjuhë të përbashkët</span> për të përshkruar gjendjet shëndetësore, në mënyrë që të përmirësojmë komunikimin mes përdoruesve të ndryshëm, si:
              punëtorët shëndetësor, hulumtuesit, politikbërësit dhe publikun, përfshirë personat me aftësi të kufizuara.
            </p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>Të lejojë <span>krahasimin e të dhënave</span> mes shteteve, disiplinave shëndetësorë, shërbimeve dhe kohëve të ndryshme.</p>
          </div>
          <div className="list-item">
            <div className="list-bullet"></div>
            <p>Të ofroj një <span>skemë kodimi sistematik</span> për sistemet e informatave shëndetësore.</p>
          </div>
        </div>
      </div>

      <div className="bottom-paragraph-container col-lg-10 col-md-12 col-sm-12">
        <p>
          KNF është përdorur si bazë teorike mbi të cilën janë ndërtuar instrumentet për vlerësimin pedagogjik të fëmijëve me nevoja të veçanta për shkak se ajo
          ofron një gjuhë të përbashkët dhe rrjedhimisht kontribuon në fuqizimin e koordinimit dhe komunikimit mes palëve të përfshira.
        </p>
      </div>
    </div>
  )
}

export default SintezaBiopsikosociale
