import { useEffect } from "react";
import "./Modal.scss";

function Modal({ children, closeModal }) {
  useEffect(() => {
    // Add the no-scroll class to the body when the component mounts
    document.body.classList.add("no-scroll");

    // Remove the no-scroll class from the body when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className="modal-container" onClick={() => closeModal()}>
      {children}
    </div>
  );
}

export default Modal;
