import perjashtimi from './../../../../../../assets/screens/module-assets/dropdown-perjashtimi.svg'
import segregimi from './../../../../../../assets/screens/module-assets/dropdown-segregimi.svg'
import integrimi from './../../../../../../assets/screens/module-assets/dropdown-integrimi.svg'
import gjitheperfshirja from './../../../../../../assets/screens/module-assets/dropdown-gjitheperfshirja.svg'

export const dropdownData = [
    {
        id: 1,
        imageSrc: perjashtimi,
        title: 'Përjashtimi',
        content: 'kur nxënësit e caktuar pengohen plotësisht që të marrin pjesë në arsim.'
    }
    ,
    {
        id: 2,
        imageSrc: segregimi,
        title: 'Segregimi',
        content: 'kur disa nxënës lejohen të ndjekin shkollimin, por duhet të marrin pjesë në klasat e tyre, të ndarë nga fëmijët që janë të ndryshëm nga ata.'
    }
    ,
    {
        id: 3,
        imageSrc: integrimi,
        title: 'Integrimi',
        content: 'kur fëmijët që janë të ndryshëm nga njëri- tjetri lejohen të ulen së bashku në të njëjtën klasë, por nuk ka konsideratë nëse të gjithë fëmijët po mësojnë apo jo.'
    }
    ,
    {
        id: 4,
        imageSrc: gjitheperfshirja,
        title: 'Gjithëpërfshirja',
        content: 'kur fëmijët që janë të ndryshëm nga njëri-tjetri lejohen të ulen së bashku në të njëjtën klasë dhe të gjithë fëmijët marrin mbështetjen që ata kanë nevojë për pjesëmarrje të plotë në mësimnxënie.'
    }
]