import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import "./Navbar.scss";

import { GrFormClose } from "react-icons/gr";
import {
  setLoginModal,
  setLogout,
  setRegisterModal,
} from "../../../redux/app/auth/actions";

function Navbar() {
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState(false);
  const { authenticated, user } = useSelector((state) => state?.user);
  const navigate = useNavigate();

  function showNavbar() {
    setToggleMenu(!toggleMenu);
  }

  return (
    <div className="nav-switch">
      {!toggleMenu && (
        <div className="navbar-container container">
          <div className="navbar-left">
            <div className="navbar-logo">
              <div onClick={() => navigate('/')} className="logo-img"></div>
            </div>
          </div>
          <div className="nav-right">
            <div className="nav-links">
              <Link to="/" className="nav-link">
                Home
              </Link>
              <Link to="/trajnimi-info" className="nav-link">
                Rreth trajnimit
              </Link>

              {authenticated ? (
                <div
                  className="nav-link"
                  onClick={() => {
                    dispatch(setLogout());
                    dispatch(setLoginModal());
                    window.location.reload();
                  }}
                >
                  Shkyqu
                </div>
              ) : (
                <div
                  className="nav-link register-link"
                  onClick={() => {
                    dispatch(setRegisterModal());
                  }}
                >
                  Regjistrohu
                </div>
              )}
            </div>
            <div
              className="user-icon"
              onClick={() => {
                if(authenticated){
                  navigate('/myprofile')
                }
                else{
                  dispatch(setLoginModal())
                }
              }}
            ></div>
          </div>

          <div className="hamburger-menu" onClick={showNavbar}>
            <div className="hr"></div>
            <div className="hr"></div>
            <div className="hr"></div>
          </div>
        </div>
      )}
      {toggleMenu && (
        <div className="responsive-nav-container">
          <div className="responsive-nav-head">
            <div
              className="responsive-nav-logo"
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              <div className="logo-img"></div>
            </div>

            <div className="responsive-nav-hamburgermenu">
              <GrFormClose size={40} onClick={showNavbar} />
            </div>
          </div>

          <div className="responsive-nav-links">
            <div className="responsive-nav-link">
              <div
                className="link"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/" className="nav-link">
                  <p>Home</p>
                </Link>
              </div>
            </div>
            <div className="responsive-nav-link">
              <div
                className="link"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/trajnimi-info" className="nav-link">
                  <p>Rreth trajnimit</p>
                </Link>
              </div>
            </div>
            <div className="responsive-nav-link">
              <div
                className="link"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/myprofile" className="nav-link">
                  <p>Profili im</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="responsive-nav-auth">
            <div className="responsive-nav-buttons">
              {authenticated ? (
                <div
                  className="btn login"
                  onClick={() => {
                    dispatch(setLogout());
                    window.location.reload();
                  }}
                >
                  <p>Shkyqu</p>
                </div>
              ) : (
                <div className="btn login"
                  onClick={() => {
                    setToggleMenu(false);
                    dispatch(setLoginModal());
                  }}
                >
                  <p>Kyqu</p>
                </div>
              )}
              {!authenticated && (
                <div
                  className="btn signup"
                  onClick={() => {
                    setToggleMenu(false);
                    dispatch(setRegisterModal());
                  }}
                >
                  <p>Regjistrohu</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
