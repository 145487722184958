import React from "react";
import "./ArsimiGjitheperfshires.scss";

function ArsimiGjitheperfshires() {
  return (
    <div className="main-container container">
      <div className="list col-lg-10 col-md-12 col-sm-12">
        <div className="list-item">
          <div className="list-bullet"></div>
          <p className="list-item-text">Është një dimension i bazuar në të drejta;</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>Siguron paanshmëri / drejtësi në qasje dhe pjesëmarrje;</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>
            I përgjigjet pozitivisht nevojave individuale arsimore të të gjithë
            fëmijëve;
          </p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>Ka fëmijën në qendër të vëmendjes;</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>
            Sistemi i arsimit i përshtatet fëmijëve të ndryshëm (nuk detyron
            fëmijën që të përshtatet);
          </p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>Së bashku me sektorët e tjerë;</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>
            Angazhohet për të siguruar që çdo fëmijë - pa dallim gjinie, gjuhe,
            aftësie, besimi dhe kombësie – përkrahet për të mësuar së bashku me
            bashkëmoshatarët.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArsimiGjitheperfshires;
