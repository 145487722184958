import React from 'react'
import './DemtimetAftesiaKufizuar.scss'

function DemtimetAftesiaKufizuar() {
  return (
    <div className='demtimetaftesiakufizuar-container container'>
        <h4 className='question-1 col-lg-10 col-md-12 col-sm-12'>Çfarë është dëmtimi?</h4>
          <p className='answer col-lg-10 col-md-12 col-sm-12'><span className='answer-span'>Dëmtimi</span>  është çdo humbje ose anomali në strukturën psikologjike, fiziologjike ose anatomike (mungesa e një pjese apo e të gjithë gjymtyrëve ose organeve të gjymtyrëve ose trupit që nuk funksionojnë plotësisht).
            <br /><br />
            Dëmtimi mund të jetë afatgjatë ose afatshkurtër. <br />
            Llojet e dëmtimeve janë: fizike, shqisore, neurologjike, intelektuale. <br />
            Dëmtimet e shumëfishta janë kur një person ka më shumë se një dëmtim në të njëjtën kohë. <br />
            Dëmtimi mund të jetë pasojë e kushteve të shkaktuara nga sëmundjet ose lëndimet që ndikojnë në funksionimin ose dukjen e personave.
          </p>

          <h4 className='row cards-title col-lg-10 col-md-12 col-sm-12'>Shkaktarët e dëmtimeve përfshijnë:</h4>
          <div className='demtimet-cards-container col-12'>
            <div className="demtimet-card-container">
              <h5 className="card-answer">Faktorët gjenetik</h5>
            </div>
            <div className="demtimet-card-container">
              <h5 className="card-answer">Sëmundje infektive dhe jo-infektive</h5>
            </div>
            <div className="demtimet-card-container">
              <h5 className="card-answer">Lëndimi, kequshqyerja apo sëmundje para ose pas lindjes</h5>
            </div>
          </div>

    </div>
  )
}

export default DemtimetAftesiaKufizuar