export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_DISPATCHER = "LOGIN_USER_DISPATCHER";
export const LOGIN_MESSAGE = "LOGIN_MESSAGE";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER = "UPDATE_USER";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";

export const LOGIN_MODAL = "LOGIN_MODAL";
export const REGISTER_MODAL = "REGISTER_MODAL";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_DISPATCHER = "REGISTER_USER_DISPATCHER";


export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

