import React, { useState, useEffect } from 'react';
import './MemoryGameQuestion.scss';

function MemoryGameQuestion({ imageSrc, questionOptions, updateSelectedOption, originalAnswer }) {
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        // Set the initially selected option based on the originalAnswer when the component mounts
        setSelectedOption(originalAnswer);
    }, [originalAnswer]);

    const handleOptionChange = (event, optionText) => {
        setSelectedOption(optionText);
        updateSelectedOption(imageSrc, optionText);
    };

    return (
        <div className="question-container">
            <div className="img-container" style={{ backgroundImage: `url(${imageSrc})` }}></div>
            <div className="input-form-container">
                {questionOptions.map((option) => {
                    const uniqueId = `img-${imageSrc}-option-${option.id}`;
                    const groupName = `group-${imageSrc}`;
                    const isSelected = selectedOption === option.questionOption;

                    return (
                        <div className={`input-container ${isSelected ? 'input-active' : ''}`} key={uniqueId}>
                            <input
                                type="radio"
                                id={uniqueId}
                                name={groupName}
                                value={uniqueId}
                                checked={isSelected}
                                onChange={() => handleOptionChange(uniqueId, option.questionOption)}
                            />
                            <label
                                htmlFor={uniqueId}
                                className={`input-checkbox-displayed ${isSelected ? 'input-active' : ''}`}
                            ></label>
                            <label htmlFor={uniqueId}>{option.questionOption}</label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MemoryGameQuestion;