import React, { useState } from 'react'
import './DropDownContainer.scss'

function DropDownContainer({title, content}) {
    
    const [toggleDropDown, setToggleDropDown] = useState(false);

    function handleDropDown(){
        setToggleDropDown(!toggleDropDown);
    }

    return (
        <div className="parimetarsimit-dropdown-container">
            <div className="parimetarsimit-dropdown-subcontainer" onClick={handleDropDown}>
            <p>{title}</p>
                {!toggleDropDown && <div className="dropdown-icon"></div>}
                {toggleDropDown && <div className="up-icon"></div>}
            </div>
            {toggleDropDown &&
            <div className="parimetarsimit-dropdown-option">
            <p>{content}</p>
            </div>
            }
        </div>
    )
}

export default DropDownContainer