export const questions = [
    {
      number: 1,
      content1: "Formulari i raportimit nga shkolla dhe raporti i monitorimit përbëhet nga dy pjesë: pjesa e raportit dhe raporti i monitorimit që i dërgohen Ekipit Vlerësues komunal.",
      options: [
        { id: 'question1-option1', value: 'op1.1', label: 'True' },
        { id: 'question1-option2', value: 'op1.2', label: 'False' },
      ],
      correctAnswer: 'True'
    },
    {
      number: 2,
      content1: "Lista e cilësorëve shkon nga 0 deri në 5.",
      options: [
        { id: 'question2-option1', value: 'op2.1', label: 'True' },
        { id: 'question2-option2', value: 'op2.2', label: 'False' },
      ],
      correctAnswer: 'False'
    },
]