import redRightArrow from "./../../../assets/screens/module-assets/red-down-arrow.svg";

export const burimetData = [
  {
    id: "moduli-1",
    burimet: [
      {
        id: 1,
        title:
          "Konventa për Drejtat e Përsonave me Aftësi të Kufizuara",
      },
      {
        id: 2,
        title:
          "Konventa për të Drejtat e Fëmijes",
      },
      {
        id: 3,
        title:
          "Korniza-Kurrikulare-e Kosoves",
      },
      {
        id: 4,
        title:
          "LIGJI NR. 04 L-032 PËR ARSIMIN PARAUNIVERSITAR NË REPUBLIKËN E KOSOVËS",
      },
      {
        id: 5,
        title:
          "Plani-Individual-i-Arsimit-PIA",
      },
      {
        id: 6,
        title:
          "Strategja-e-Arsimit-2022-2026",
      },
      {
        id: 7,
        title:
          "Udhëzimi Administrativ për Vlerësimin Pedagogjik të Fëmijëve me Nevoja të Veçanta",
      },
      {
        id: 8,
        title:
          "Udhëzimi Administrativ për vlerësimin e nxenesve me nevoja të veçanta",
      },
    ],
  },
  {
    id: "moduli-2",
    burimet: null,
  },
  {
    id: "moduli-3",
    burimet: [
      {
        id: 1,
        title:
          "Instrumentet për Vlerësimin Pedagogjik të Fëmijeve me Nevoja të Veçanta",
        
      }
    ],
  },
];
