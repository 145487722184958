import integrimi from './../../../../../../assets/screens/module-assets/integrimi.jpg'
import gjitheperfshirja from './../../../../../../assets/screens/module-assets/gjitheperfshirja.jpg'
import perjashtimi from './../../../../../../assets/screens/module-assets/perjashtimi.jpg'
import segregimi from './../../../../../../assets/screens/module-assets/segrenimi.jpg'

export const questionData = [
    {
        id: 1,
        imageSrc: integrimi,
        questionOptions: [
            {
                id: 1,
                questionOption: 'Segregimi'
            }
            ,
            {
                id: 2,
                questionOption: 'Gjithëpërfshirja'
            }
            ,
            {
                id: 3,
                questionOption: 'Integrimi'
            }
            ,
            {
                id: 4,
                questionOption: 'Përjashtimi'
            }
        ]
        ,
        correctAnswer: 'Integrimi'
    }
    ,
    {
        id: 2,
        imageSrc: gjitheperfshirja,
        questionOptions: [
            {
                id: 1,
                questionOption: 'Segregimi'
            }
            ,
            {
                id: 2,
                questionOption: 'Gjithëpërfshirja'
            }
            ,
            {
                id: 3,
                questionOption: 'Integrimi'
            }
            ,
            {
                id: 4,
                questionOption: 'Përjashtimi'
            }
        ]
        ,
        correctAnswer: 'Gjithëpërfshirja'
    }
    ,
    {
        id: 3,
        imageSrc: perjashtimi,
        questionOptions: [
            {
                id: 1,
                questionOption: 'Segregimi'
            }
            ,
            {
                id: 2,
                questionOption: 'Gjithëpërfshirja'
            }
            ,
            {
                id: 3,
                questionOption: 'Integrimi'
            }
            ,
            {
                id: 4,
                questionOption: 'Përjashtimi'
            }
        ]
        ,
        correctAnswer: 'Përjashtimi'
    }
    ,
    {
        id: 4,
        imageSrc: segregimi,
        questionOptions: [
            {
                id: 1,
                questionOption: 'Segregimi'
            }
            ,
            {
                id: 2,
                questionOption: 'Gjithëpërfshirja'
            }
            ,
            {
                id: 3,
                questionOption: 'Integrimi'
            }
            ,
            {
                id: 4,
                questionOption: 'Përjashtimi'
            }
        ]
        ,
        correctAnswer: 'Segregimi'
    }
]