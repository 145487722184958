import React from 'react';
import { Link , useParams } from 'react-router-dom';
import './CfareEshteAftesiaKufizuar.scss';
import { cfareEshteData } from './cfareEshteAftesiaKufizuarData';
import Video from '../../../../shared/Video/Video';

function CfareEshteAftesiaKufizuar() {
  const { moduleId }= useParams();
  return (
    <div className="container cfareeshteaftesiakufizuar-container">
      <div className="col-lg-10 col-md-12 col-sm-12 paragraphs-container">
        <p>Persona/fëmijë me aftësi të kufizuara janë ata/ato që kanë dëmtime afatgjata fizike, mendore,
          intelektuale ose shqisore, të cilat në ndërveprim me barriera / pengesa të ndryshme mund të pengojnë pjesëmarrjen
          e tyre të plotë dhe efektive në shoqëri në baza të barabarta me të tjerët
          (Konventa e OKB-së për të drejtat e personave me aftësi të kufizuara, 2008)</p>
        <p>Konventën e OKB-së për të drejtat e personave me aftësi të kufizuara mund ta gjeni të plotë tek <Link to={`/modules/${moduleId}/burimet`}>Burimet.</Link></p>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 barrierat-ilustrated-container">
        <p>Barrierat / pengesat mund të jenë:</p>
        <div className="barrierat-ilustrated-subcontainer" >
          {cfareEshteData.map((data) => {
            return (
                <div className='barriera-container' key={data.id}>
                  <img className="img-container" width={data.width} height={data.height} src={data.imgUrl} alt={data.alt}></img>
                  <div className="title-container">
                    <div className="title-subcontainer">
                      <h3 className='title'>{data.title}</h3>
                    </div>
                  </div>
                </div>
            );
          })}
        </div>
      </div>
      <Video urlSource="https://www.youtube.com/embed/dbmNNb6pp5k" />
      <div className="scroll-container">
        <div className="scroll-content">
          <p className='title'>Dallimi mes te dëmtimit dhe Aftësisë se Kufizuar</p>
          <p>Dëmtimi dhe aftësia e kufizuar janë koncepte të lidhura, por kanë dallime të theksuara në kontekstin e arsimit.  <span>Dëmtimi</span> i referohet një humbjeje ose kufizimi të funksionit fizik, mendor ose shqisor, siç janë: dëgjimi, lëvizshmëria ose njohja. <span>Aftësia e kufizuar</span> nga ana tjetër, i referohet barrierave sociale dhe mjedisore që pengojnë personat me dëmtime, që të marrin pjesë aktivisht dhe në mënyrë të barabartë në shoqëri.</p><br></br>

          <p>Në vijim do provojmë ta sqarojmë dallimin mes dëmitimit dhe aftësisë së kufizuar përmes tregimeve të katër nxënësve.</p><br></br>

          <p>Sara është nxënëse e klasës se katërt dhe ka vështirësi në shikimi.</p> 
          <p>Bardhi, nxënës i klasës së tetë ka paralizë cerebrale.</p>
          <p>Dora është nxënëse në klasën e dytë dhe ka vështirësi në të nxënë – ajo është diagnostikuar me Çrregullim të Hiperaktivitetit dhe Deficitit të Vëmendjes (ADHD).</p>
          <p>Noli është nxënës i klasës së shtatë dhe ka vështirësi në të dëgjuar.</p><br></br>

          <p>Të katër nxënësit kanë një dëmtim të caktuar. Ata mund të kenë ose jo aftësi të kufizuar në varësi të pengesa respektivisht lehtësuesve mjedisor në shkollë dhe shoqëri.</p><br></br>

          <p>Sara mund të mos jetë në gjendje të shohë tabelën ose të lexojë tekstet shkollore, por ajo nuk do të kishtë aftësi të kufizuara nëse shkolla do t’i siguronte materiale si p.sh. tekste me shkronja të zmadhuara, audio materiale ose tekste në Braille, si dhe nëse do t’i lejohej apo mundësohet të përdor pajisje ndihmëse, të tilla si: zmadhuesit ose lexuesit e ekranit.</p><br></br>

          <p>Noli mund të mos jetë në gjendje të dëgjojë mësimdhënësit ose shokët e tij të klasës, por ai nuk do të kishte aftësi të kufizuara nëse shkolla do t’i ofronte interpret të gjuhës së shenjave, mbishkrime ose sisteme amplifikuese dhe të siguronte që ambienti i klasës së Nolit të jetë i përshtatshëm nga ana akustike.</p><br></br>

          <p>Dora nuk do të kishte aftësi të kufizuara nëse do t’i përshtateshin planet dhe programet mësimore, nëse do t’i ofroheshin udhëzime dhe do përdoreshin metoda të mësimdhënies dhe të vlerësimit të  përshtatshme për stilin dhe nevojat e saj të të nxënit.</p><br></br>

          <p>Ngjashëm, Bardhi nuk do të kishte aftësi të kufizuara nëse do kishte qasje në mjete të përshtatshme lëvizëse, si: karriget me rrota ose paterica, dhe nëse mjedisi i shkollës do ishte përshtatur me nevojat e tij, si p.sh. pjerrina të përshtatshme, ashensorë ose tualet të qasshem.</p><br></br>

          <p>Prandaj, aftësia e kufizuar nuk është një karakteristikë e qenësishme e një personi, por një rezultat i ndërveprimit midis dëmtimit të një personi dhe kontekstit në të cilin ai apo ajo jeton dhe vepron.</p><br></br> 

          <p>Sipas Konventës së Kombeve të Bashkuara për të Drejtat e Personave me Aftësi të Kufizuara, persona me aftësi të kufizuara janë ata apo ato që kanë dëmtime afatgjata fizike, mendore, intelektuale ose shqisore, të cilat në ndërveprim me pengesa të ndryshme mjedisore mund të pengojnë pjesëmarrjen e tyre të plotë dhe efektive në shoqëri në baza të barabarta me të tjerët.
          </p>
        </div>
      </div>
      <div className="col-lg-10 col-md-12 col-sm-12 paragraphs-container">
        <p>Aftësia e kufizuar është pjesë e <span>gjendjes së njeriut.</span> Pothuaj secili do të jetë përkohësisht ose përherë i dëmtuar në një pikë të caktuar në jetë,
           dhe ata të cilët mbijetojnë deri në moshë më të shtyer do të përjetojnë vështirësi në funksionim.
        </p>
        <p>Aftësia e kufizuar është komplekse, dinamike, shumëdimensionale dhe e kontestueshme.</p>
        <p>Përvoja e aftësisë së kufizuar që rezulton prej ndërveprimit mes gjendjes shëndetësore, faktorëve personal dhe faktorëve mjedisorë ndryshon shumë.</p>
      </div>
    </div>
  );
}

export default CfareEshteAftesiaKufizuar;