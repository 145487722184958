import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.scss";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, useField, ErrorMessage } from "formik";
import { object, string } from "yup";
import {
  setLoginModal,
  setLoginDispatcher,
  setRegisterModal
} from "../../../../redux/app/auth/actions";
import { useNavigate } from "react-router-dom";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, isLoading } = useSelector((state) => state?.user);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const LoginValidation = object().shape({
    email: string()
      .required("Ju lutem plotesoni kete te dhene")
      .email("Ju lutem vendosni email valide"),
    password: string()
      .min(3, "Ju duhet te keni minimum 3 karaktere")
      .required("Ju lutem plotesoni kete te dhene"),
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className=" col-lg-12 col-md-12 col-sm-12 fluid-container login-container">
      <div
        className="login-form-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="title">Kyqu</h3>
        <p className="description">
          Nuk posedoni ende nje llogari?{" "}
          <span 
            className="signup-link" 
            onClick={() => {
              dispatch(setLoginModal())
              dispatch(setRegisterModal())
            }}>Regjistrohu</span>
        </p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            dispatch(setLoginDispatcher(values));
          }}
          validationSchema={LoginValidation}
        >
          {({ errors, values }) => {
            return (
              <Form className="login-form">
                <div>
                  <div className="input-lg-container">
                    <div className="mail-img"></div>
                    <Field name="email" placeholder="Emaili" id="email" />
                  </div>
                  {errors.email && <p className="error-msg">{errors.email}</p>}
                </div>
                <div>
                  <div className="input-lg-container">
                    <div className="lock-img" onClick={togglePasswordVisibility}></div>
                    <Field
                      name="password"
                      placeholder="Fjalekalimi"
                      id="password"
                      type={passwordVisible ? "text" : "password"}
                    />
                  </div>
                  {errors.password && (
                    <p className="error-msg">{errors.password}</p>
                  )}
                </div>
                <p className="description">
                  Forgot your password ?{" "}
                  <span 
                    className="signup-link" 
                    onClick={() => {
                      dispatch(setLoginModal())
                      navigate('/reset-password')
                    }}>Reset password</span>
                </p>
                <button type="submit" className="btn-container btn-text">
                  KYQU
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Login;