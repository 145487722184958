import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { api } from "../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function FeedbackForm({ updateLoading }) {
  const navigate = useNavigate();
  // Validation schema for the feedback form
  const FeedbackValidation = Yup.object().shape({
    // Define validation rules for each radio group here
    question1: Yup.string().required("Please select an option"),
    question2: Yup.string().required("Please select an option"),
  });

  async function sendFeedback(values) {
    // Set loading to true before making the API call
    updateLoading(true);
  
    // Transform values into the new format
    const feedbackData = {
      feedback: {
        "0": values.question1,
        "1": values.question2,
      },
    };
  
    try {
      // Make API call
      let sendFeedback = await api.post(`feedback`, feedbackData);
  
      if (sendFeedback.status === 200) {
        toast.success("Message sent successfully!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
      } else {
        toast.error("Please try again later!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
    } finally {
      updateLoading(false);
    }
  }

  return (
    <Formik
      initialValues={{
        question1: "",
        question2: "",
      }}
      validationSchema={FeedbackValidation}
      onSubmit={(values) => {
        sendFeedback(values);
        // Handle form submission logic here
      }}
    >
      {({ errors, touched, values }) => (
        <Form className="feedback-content col-lg-7 col-md-12 col-sm-12">
          <div className="list">
            {/* ... other JSX code ... */}
            <div className="list-item" id="list-item-1">
              <div className="list-paragraph-container">
                <div className="list-paragraph-number">
                  <p className="number-of-paragraph">1.</p>
                </div>
                <p className="content-of-paragraph">
                  Pas këtij trajnimi, sa vlerësoni që keni njohuri rreth temës së
                  trajtuar?
                </p>
              </div>
              <div className="check-container">
                {["aspak", "pak", "mesatarisht", "shume"].map((option) => (
                  <div key={option} className="radio-container">
                    <Field
                      type="radio"
                      id={`question1-${option}`}
                      name="question1"
                      value={option}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor={`question1-${option}`}
                      className={`input-checkbox-displayed ${
                        values.question1 === option ? "input-active" : ""
                      }`}
                    ></label>
                    <label
                      className="option-label"
                      htmlFor={`question1-${option}`}
                    >
                      {option}
                    </label>
                  </div>
                ))}
                {errors.question1 && touched.question1 && (
                  <p className="error-msg">{errors.question1}</p>
                )}
              </div>
            </div>

            <div className="list-item" id="list-item-2">
              <div className="list-paragraph-container">
                <div className="list-paragraph-number">
                  <p className="number-of-paragraph">2.</p>
                </div>
                <p className="content-of-paragraph">
                  A mendoni se keni mësuar rreth temës në këtë trajnim?
                </p>
              </div>

              <div className="check-container">
                {["po", "jo"].map((option, optionIndex) => (
                  <div key={optionIndex} className="radio-container">
                    <Field
                      type="radio"
                      id={`question2-${optionIndex}`}
                      name="question2"
                      value={option}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor={`question2-${optionIndex}`}
                      className={`input-checkbox-displayed ${
                        values.question2 === option ? "input-active" : ""
                      }`}
                    ></label>
                    <label
                      className="option-label"
                      htmlFor={`question2-${optionIndex}`}
                    >
                      {option}
                    </label>
                  </div>
                ))}
                {errors.question2 && touched.question2 && (
                  <p className="error-msg">{errors.question2}</p>
                )}
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button type="submit" className="btn-container">
              <p className="btn-text">Dergo Mesazhin</p>
              <div className="right arrow-image-container"></div>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FeedbackForm;
