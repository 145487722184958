import React from 'react'
import './Mekanizmat.scss'

function Mekanizmat() {
  return (
    <div className='container mekanizmat-container'>
    <div className="col-lg-10 col-md-12 col-sm-12">
      <p>
      Arsimi gjithëpërfshirës si filozofi arsimore për t'u jetësuar kërkon edhe mekanizma mbështetës të cilët i ndihmojnë institucionet arsimore në sigurimin e gjithëpërfshirjes.
      </p>
    </div>
    <div className="col-lg-10 col-md-12 col-sm-12 image-container">
      <div className="image-subcontainer"></div>
    </div>
    <div className="col-lg-10 col-md-12 col-sm-12 content-card-container">
      <p>Në Kosovë funksionojnë disa mekanizma të cilët i mbështesin institucionet edukative dhe arsimore në këtë fushë, si:</p>
      <div className="list">
        <div className="list-item">
          <div className="list-bullet"></div>
          <p className="list-item-text">Edukatori(ja) mbështetës(e),</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>Mësimëdhënësi(ja) mbështetës(e), dhe</p>
        </div>
        <div className="list-item">
          <div className="list-bullet"></div>
          <p>
            Asistenti(ja) për fëmijët/nxënësit me nevoja të veçanta arsimore.
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Mekanizmat