import React, { useEffect, useState } from 'react';
import { trainingPathCardData } from '../../components/screens/Home/TrainingPathCard/trainingPathCardData';
import './Home.scss';
import Loader from './../../components/shared/Loader/Loader';
import CardModule from '../../components/screens/Home/CardModule/CardModule';
import TrainingPathCard from '../../components/screens/Home/TrainingPathCard/TrainingPathCard';
import { api } from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterModal } from '../../redux/app/auth/actions';
import { handleApiError } from '../../api/utils';

function Home() {
  const dispatch = useDispatch();
  const [module, setModule] = useState([]);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const { authenticated } = useSelector((state) => state?.user);

  useEffect(() => {
    if (authenticated) {
      setLoading(true);
      fetchModulesAndUserData();
    } else {
      setModule([]);
      setUserData({});
    }
  }, [authenticated]);

  const fetchModulesAndUserData = async () => {
    await Promise.all([getModule(), fetchUserData()]).finally(() =>
      setLoading(false)
    );
  };

  const getModule = async () => {
    try {
      const moduleResponse = await api.get('module');
      if (moduleResponse && moduleResponse.data.data) {
        setModule(moduleResponse.data.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchUserData = async () => {
    if (localStorage.getItem('user')) {
      try {
        const response = await api.get('user/myDetails');
        if (response && response.data?.data) {
          setUserData(response.data.data);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className='home-container fluid-container'>
          <div
            className={
              false
                ? 'fluid-container banner-container active'
                : 'fluid-container banner-container'
            }
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 banner-subcontainer'>
                  <div className='banner-left col-lg-5 col-md-5 col-sm-10'>
                    <h1 className='banner-title'>
                      Trajnimi për KNF (Klasifikimi Ndërkombëtar i Funksionimit)
                    </h1>
                    <h2 className='banner-subtitle'>
                      Përdorimi i instrumenteve për vlerësimin pedagogjik të
                      nxënësve me nevoja të veçanta
                    </h2>
                    <p className='banner-description'>
                      Trajnimi i dixhitalizuar Klasifikimi Ndërkombëtar të
                      Funksionimit - Përdorimi i instrumenteve për vlerësimin
                      pedagogjik të nxënësve me nevoja të veçanta përfshirë
                      materialet ekzistuese të programit të trajnimit u krijua
                      me mbështetjen e projektit ‘’Fuqizimi i arsimit
                      gjithëpërfshirës dhe punësimit për fëmijët dhe të rinjtë
                      me aftësi të kufizuara‘’ të financuar nga Save the
                      Children Itali.
                    </p>
                    <p className='banner-description'>
                      Kursi është i hapur për të gjithë mësimdhënësit dhe është
                      falas!
                    </p>
                    {!authenticated && (
                      <div
                        className='banner-register-btn-container'
                        onClick={() => {
                          dispatch(setRegisterModal());
                        }}
                      >
                        <div className='banner-register-btn'>
                          <p>Regjistrohu</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='banner-right col-lg-6 col-md-7 col-sm-10'>
                    <div className='banner-image-container'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container mt-5'>
            <div className='row mb-4'>
              <div className='col-lg-12 col-md-12 col-sm-10 training-menu-container'>
                <div className='title-container'>
                  <h4 className='title'>Menyja e trajnimit</h4>
                </div>
                <div className='description-container'>
                  <p className='description'>
                    Programi i trajnimit Klasifikimi Ndërkombëtar të
                    Funksionimit dhe përdorimi i instrumenteve për vlerësimin
                    pedagogjik të nxënësve me nevoja të veçanta është i ndarë në
                    tre module. Pas përfundimit të tre moduleve, shfaqet një
                    test në formë të kuizit. Ju duhet t’i përgjigjeni saktë së
                    paku 80% të pyetjeve për të kaluar në modulin pasues dhe për
                    të përfunduar trajnimin.
                  </p>
                </div>
              </div>
            </div>
            {authenticated && (
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className='row cards-container'>
                    {module &&
                      module.length > 0 &&
                      module.map((card, index) => {
                        const userPage = userData.lastReadPage?.find(
                          (page) => page.moduleId === card._id && page.quizResult?.passed
                        );
                        const hasPassed = !!userPage;

                        return (
                          <div
                            className={`col-lg-4 col-md-6 col-sm-10 card-cont`}
                            key={index}
                          >
                            <CardModule
                              cardId={index + 1}
                              title={card.title}
                              description={card.description}
                              buttonText={hasPassed ? 'Përfunduar' : 'Vazhdo'}
                              path={card.slug}
                              hasPassed={hasPassed}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='container'>
            <div className='row training-path-container'>
              <div className='col-lg-12 col-md-12 col-sm-12 training-path-subcontainer'>
                <div className='col-lg-6 col-md-5 col-sm-10 path-image-container'>
                  <div className='path-image'></div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-10 training-path'>
                  <h1 className='title'>
                    Si të përfundoni me sukses trajnimin:
                  </h1>
                  {trainingPathCardData?.map((card) => (
                    <TrainingPathCard
                      key={card.id}
                      title={card.title}
                      path={card.path}
                      description={card.description}
                      img={card.img}
                      alt={card.alt}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
