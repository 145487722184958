import React, { useState, useEffect } from "react";
import "./CardModule.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginModal } from "../../../../redux/app/auth/actions";
import check from './../../../../assets/screens/home-assets/check.svg'

function CardModule({hasPassed, cardId, title, description, buttonText, path}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticated } = useSelector((state) => state?.user);

  const navigatePage = async () => {
    if (authenticated && path) {
      await navigate(`/modules/${path}`);
    } else {
      dispatch(setLoginModal());
    }
  };
  return (
    <div className={`card-container ${hasPassed ? 'done' : 'not-done'}`} key={cardId}>
      <h3 className={`card-title ${hasPassed ? 'done' : 'not-done'}`}>{title}</h3>
      <p className={`card-description ${hasPassed ? 'done' : 'not-done'}`}>{description}</p>
      <div className="card-bottom">
        <div className={`card-btn ${hasPassed ? 'done' : 'not-done'}`} onClick={() => navigatePage()}>
          <p className={`card-btn-text ${hasPassed ? 'done' : 'not-done'}`}>{buttonText}</p>
          {hasPassed &&
            <div className="check-container" style={{backgroundImage : `url(${check})`}}></div>
          }
        </div>
        <div className={`card-progress ${hasPassed ? 'done' : 'not-done'}`}>
          <p>{cardId}</p>
        </div>
      </div>
    </div>
  );
}

export default CardModule;
