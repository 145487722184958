import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";
import { tokenValidation } from "../api/utils";
import { setLoginDispatcher } from "../redux/app/auth/actions";

const PublicRoute = ({ path, exact, children }) => {
  const { authenticated } = useSelector((state) => state.user);

  return authenticated ? <Navigate to="/" /> : <>{children}</>;
};

export default PublicRoute;
