import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer-container fluid-container">
      <div className="row">
        <p className="title">© Save the Children</p>
      </div>
    </div>
  );
}

export default Footer;
